.content {
  width: 80%;
  margin: 4rem auto 8rem;
  padding: 20px;
  // min-height: 70vh;
  background: #fff;
  box-shadow: 0px 5px 5px rgba(238, 239, 239, 0.15);
  border-radius: 5px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1rem;
  color: #29394d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top_actions {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.tableContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  height: max-content;
  padding-bottom: 15px;
  margin-top: 2rem;
}
.table {
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;
    i {
      cursor: pointer;
      color: #6d7f92;

      &.iconActive {
        color: #29394d;
      }
    }

    .sortBox {
      margin-left: 5px;
    }
  }

  tr {
    background: #fafbfb;
    border-bottom: 2px solid #fff;
    border-radius: 2px;
  }

  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;

    &.centerCell {
      text-align: center;
    }

    .cellIcon {
      transform: rotate(90deg);
      cursor: pointer;
      font-size: 14px;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: #f1f2f4;
      }

      &.active {
        background-color: #18a0fb;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1005 !important;
  background-color: rgba(41, 57, 77, 0.4);
}
.modalContentAfterOpen {
  .modal {
    top: 0;
    opacity: 1;
  }
}
.modalContentBeforeClose {
  .modal {
    top: 30%;
    opacity: 0;
  }
}
.modalContent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
  background: transparent;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;

  width: 500px;
  opacity: 0;
  background: #f8f9fa;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  height: 60px;
  border-radius: 5px 5px 0px 0px;
  font-size: 22px;
  line-height: 26px;
  color: #2495e1;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.body {
  padding: 0.5rem 2rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem 1rem;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mapbox {
  position: relative;
  z-index: 0;
  height: 500px;
  margin: 1rem 0 2rem;
}

.loadMore {
  width: 200px;
  border-radius: 30px;
  margin: 2rem auto 1rem;
  padding: 10px 5px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: #18a0fb;
  border: 1px solid #f1f2f4;

  &:hover {
    background-color: #f1f2f4;
  }
}

.customMarker {
  transition: all 200ms ease-in-out;
}
