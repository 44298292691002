.newsletter_section {
  background-color: #f4f4f4;
  padding: 6rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1.5rem;
  text-align: center;
}

.description {
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  margin-bottom: 2.5rem;
}

.form {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .input {
    width: 24rem;
    height: 3rem;
    border: 1px solid #000;
    background: #fff;
    padding: 12px;
    margin-right: 1rem;
  }
  .btn {
    height: 3rem;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    &_saving {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

.notice {
  font-size: 0.75rem;
  line-height: 150%;
}

.error {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 150%;
  color: red;
  text-align: left;
}

// @media screen and (min-width: 481px) and (max-width: 1024px) {
@media screen and (max-width: 1024px) {
  .newsletter_section {
    padding: 3.75rem 1.25rem;
  }
  .title {
    font-size: 2.5rem;
    width: 100%;
    max-width: 42rem;
  }

  .description {
    font-size: 1rem;
    width: 100%;
    max-width: 42rem;
  }
}

@media screen and (max-width: 480px) {
  .title,
  .description {
    width: 100%;
  }
  .form {
    flex-direction: column;
    width: 100%;
    .input {
      margin: 0;
      width: 100%;
      margin-bottom: 1rem;
    }
    .btn {
      width: 100%;
    }
  }
  .notice {
    text-align: center;
  }
}
