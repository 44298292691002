.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1005 !important;
  background-color: rgba(41, 57, 77, 0.4);
}
.modalContentAfterOpen {
  .modal {
    top: 0;
    opacity: 1;
  }
}
.modalContentBeforeClose {
  .modal {
    top: 50%;
    opacity: 0;
  }
}
.modalContent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
  background: transparent;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;

  width: 500px;
  opacity: 0;
  background: #f8f9fa;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  height: 60px;
  border-radius: 5px 5px 0px 0px;
  font-size: 22px;
  line-height: 26px;
  color: #2495e1;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.body {
  padding: 0.5rem 2rem;
}

.controls {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem 1rem;
}

.mt {
  margin-top: 1rem;
}

.errors {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: #f8d7db;
  border: 1px solid #721c24;
  color: #721c24;
  li {
    margin-left: 1rem;
    margin-bottom: 0.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.desc {
  height: 200px !important;
}
