// modal styling
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.modal_header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  background: #f3faff;
  border-bottom: 1px solid rgba(199, 232, 254, 0.5);
  border-radius: 0px 0px 5px 5px;
  font-size: 20px;
  line-height: 23px;
  color: #2495e1;

  &_sub {
    font-size: 18px;
    margin-left: 10px;
  }
}
.modal_icon {
  margin-right: 1rem;
}
.modal_body {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 39rem;
  overflow-y: scroll;
  padding: 20px 42px 42px 42px;
  border-bottom: 1px solid #f1f2f4;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.content {
  padding: 1rem;
}

.importLabel {
  display: block;
  font-size: 12px;
  line-height: 14px;
  padding: 0 15px 9px;
}

.dropzone {
  margin-top: 10px;
  background: #f8f9fa;
  border: 1px dashed #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 156px;
  padding: 15px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #6d7f92;

  i {
    color: #6d7f92;
    font-size: 2rem;
    display: block;
    margin-bottom: 8px;
  }
}

.prototype {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 50px;
  height: 28px;
  width: 180px;
  margin: 20px auto 40px;
  font-size: 12px;
  line-height: 14px;

  a {
    display: flex;
    align-items: center;
    color: #6d7f92;
  }
}

.orSep {
  text-align: center;
  line-height: 0.1em;
  position: relative;
  margin: 10px 0 25px;
  background: #fff;

  span {
    background: #fff;
    padding: 0 10px;
    color: #6d7f92;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    height: 1px;
    width: 200px;
    background-color: #b2bcc6;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
}

.labelTitle {
  color: #6d7f92;
  font-size: 14px;
  line-height: 16px;
}

.pasteBox {
  background: #f8f9fa;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d7f92;
  font-size: 12px;
  line-height: 14px;
  height: 34px;
  cursor: pointer;
  margin-bottom: 2rem;
}

.headerError {
  border: 1px solid #fe3745;
  padding: 10px;
  border-radius: 5px;
  background: #ffcace;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 16px;
}

.error {
  background-color: #ffcace;
}

.remove {
  cursor: pointer;
  font-size: 12px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
}

.table td span {
  height: 34px;
  display: flex;
  align-items: center;
}

.separator {
  border: 1px solid #f1f2f4;
  width: 100%;
  margin: 40px 0 15px;
}

.historylist {
  position: relative;
  padding: 4rem 0 2rem;
  text-align: left;

  &:before {
    position: absolute;
    left: calc(50% - 22px);
    top: 0;
    font-family: tamtam;
    content: "\e920";
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: #fff;
    background: #18a0fb;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:after {
    position: absolute;
    left: 50%;
    top: 0;
    content: " ";
    display: block;
    width: 5px;
    height: 100%;
    margin-left: -1.5px;
    background: rgba(109, 127, 146, 0.1);
    z-index: 5;
    border-radius: 5px;
  }
}
.history_item {
  margin-bottom: 15px;
}
.history_item:nth-child(odd) > div > div {
  margin-right: 20px;
}
.history_item:nth-child(even) {
  > div {
    text-align: left;
    margin-left: auto;

    > div {
      margin-left: 20px;
    }
  }

  .history_box_circle {
    left: -29px;
    right: 0 !important;
  }

  .history_box_date {
    left: -115px;
    right: auto;
    text-align: right;
  }
}

.history_box {
  position: relative;

  &_content {
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #3c4e64;
  }

  &_circle {
    position: absolute;
    right: -31px;
    top: 50%;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    z-index: 10;
    background: #2495e1;
    border: 5px solid #e4e6ea;
    box-sizing: border-box;
  }

  &_date {
    position: absolute;
    right: -115px;
    top: 48%;
    font-size: 12px;
    line-height: 14px;
    color: #2495e1;
    display: flex;
    flex-direction: column;
  }
}
.history_updatedby {
  margin-bottom: 5px;
}
.history_title {
  font-weight: normal;
}

.divider {
  &:before {
    content: "";
    display: block;
    background: #f1f2f4;
    border-radius: 2px;
    height: 1px;
    margin: 3rem 0 4rem;
  }
}
