.banner {
  height: 780px;
  background-image: url("https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/ua/home.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 4rem;
  position: relative;
  z-index: 10;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 50px;
    width: 100%;
    height: 400px;
    background-image: url("https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/ua/home_apps_icons.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    display: block;
    content: "";
    z-index: -1;
  }

  &:after {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;
    content: "";
    height: 5px;
    background-color: #29394d;
  }

  &_bottom {
    position: absolute;
    bottom: 5px;
    right: 0;
    height: 280px;
    width: 70%;
    background: rgba(24, 160, 251, 0.9);
    backdrop-filter: blur(2px);
    padding: 33px 85px;
    display: flex;
    text-align: left;

    &_left {
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      color: #f3faff;
      width: 50%;

      span {
        display: block;
        color: #112640;
      }
    }

    &_right {
      width: 50%;
      margin-left: 25px;
      font-size: 20px;
      line-height: 28px;
      color: #f3faff;
    }

    &:after {
      position: absolute;
      top: 98px;
      right: 20%;
      content: "";
      display: block;
      background: linear-gradient(
        180deg,
        #c7e8fe 0%,
        rgba(199, 232, 254, 0) 100%
      );
      filter: blur(100px);
      transform: matrix(1, 0, 0, -1, 0, 0);
      width: 526.68px;
      height: 417.68px;
      z-index: -1;
    }
  }

  &_action {
    padding: 14px 26px;
    margin: 0 auto;
    background: #06d9b1;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    border: none;
    color: #fff;
    cursor: pointer;

    svg {
      padding-top: 5px;
      margin-left: 10px;
    }
  }
}

.title {
  font-weight: 300;
  font-size: 40px;
  line-height: 32px;
  color: #d8dde2;

  &_name {
    display: block;
    font-weight: 700;
    font-size: 56px;
    line-height: 32px;
    color: #c7e8fe;
    margin: 30px 0;

    span {
      color: #18a0fb;
    }
  }
}

.subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #d8dde2;
}

.free {
  font-weight: 300;
  font-size: 40px;
  line-height: 32px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 40px 0;
}

@media screen and (max-width: 63.9375em) {
  .banner {
    &_bottom {
      width: 100%;

      padding: 33px 50px;

      &_left {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }
}

@media screen and (max-width: 39.9375em) {
  .banner {
    padding-left: 1rem;
    padding-right: 1rem;

    &:before {
      display: none;
    }

    &_bottom {
      width: 100%;
      padding: 16px 16px;
      height: 300px;

      &_left {
        font-size: 24px;
        line-height: 30px;
      }

      &_right {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .title {
    &_name {
      font-size: 44px;
    }
  }

  .free {
    font-size: 34px;
  }
}
