:global(.grid-container) {
  max-width: 87rem;
}

// modal styling
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.modal_header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  background: #f3faff;
  border-bottom: 1px solid rgba(199, 232, 254, 0.5);
  border-radius: 0px 0px 5px 5px;
  font-size: 20px;
  line-height: 23px;
  color: #2495e1;

  &_sub {
    font-size: 18px;
    margin-left: 10px;
  }
}
.modal_icon {
  margin-right: 1rem;
}
.modal_body {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 29rem;
  overflow-y: scroll;
  padding: 20px 42px 100px 42px;
  border-bottom: 1px solid #f1f2f4;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.importLabel {
  display: block;
  font-size: 12px;
  line-height: 14px;
  padding: 0 15px 9px;
}

.dropzone {
  margin-top: 10px;
  background: #f8f9fa;
  border: 1px dashed #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 156px;
  padding: 15px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #6d7f92;

  i {
    color: #6d7f92;
    font-size: 2rem;
    display: block;
    margin-bottom: 8px;
  }
}

.prototype {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 50px;
  height: 28px;
  width: 180px;
  margin: 20px auto 40px;
  font-size: 12px;
  line-height: 14px;

  a {
    display: flex;
    align-items: center;
    color: #6d7f92;
  }
}

.orSep {
  text-align: center;
  line-height: 0.1em;
  position: relative;
  margin: 10px 0 25px;
  background: #fff;

  span {
    background: #fff;
    padding: 0 10px;
    color: #6d7f92;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    height: 1px;
    width: 200px;
    background-color: #b2bcc6;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
}

.labelTitle {
  color: #6d7f92;
  font-size: 14px;
  line-height: 16px;
}

.pasteBox {
  background: #f8f9fa;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d7f92;
  font-size: 12px;
  line-height: 14px;
  height: 34px;
  cursor: pointer;
  margin-bottom: 2rem;
}

.headerError {
  border: 1px solid #fe3745;
  padding: 10px;
  border-radius: 5px;
  background: #ffcace;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 16px;
}

.rowError {
  border-bottom: 2px solid #ffcace;
}

.error {
  background-color: #ffcace;
}

.remove {
  cursor: pointer;
  font-size: 12px !important;
  color: #fe3745;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);

  div > button {
    margin-left: 1rem;
  }
}

.table td span {
  height: 34px;
  display: flex;
  align-items: center;
}
.modalContent {
  position: relative;
  &.loading {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: block;
  margin-bottom: 1rem;
}
.pointer {
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }
}
.tableContainer {
  overflow: scroll;
  min-height: 300px;
}
.table {
  width: 100%;
  th,
  td {
    padding: 0.3rem 0.5rem 0.3rem 0.3rem;
    border: 1px solid #dee2e6;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    position: relative;
  }

  .error {
    input {
      background-color: #ffcace;
    }
  }

  .mapping {
    padding: 0.5rem 1rem;
    min-width: 250px;

    &_flex {
      display: flex;
      align-items: center;

      > div {
        width: 100%;
        margin-right: 10px;
      }
    }
  }

  td .tooltip {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
    cursor: pointer;
    i:before {
      color: #fe3745;
    }
  }
}
.rowHeader {
  th,
  td {
    font-weight: 500;
    background-color: #f4f7f9;
  }
}

.mergeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;

    label {
      margin-bottom: 5px;
    }
  }

  &_left {
    border-right: 1px solid #dee2e6;
  }
}

.splitField {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;

  label {
    cursor: pointer;
  }
}

.menuDropdown {
  position: absolute;
  left: 92px;
  top: 45px;
  border: 1px solid #18a0fb;
  border-radius: 4px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  background: #fff;
  z-index: 1005;
  width: 300px;
  min-height: 100px;
  white-space: break-spaces;
  padding: 10px;

  &:before {
    content: "";
    position: absolute;
    right: 47%;
    top: -11px;
    // border-color: transparent transparent transparent #18a0fb;
    // border-style: solid;
    // border-width: 7px 0 7px 10px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #18a0fb;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &_actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #dee2e6;
  }
}

.preview {
  padding: 10px 0;
  min-height: 100px;
}

.mappingDropdown {
  position: absolute;
  right: 3.7rem;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #18a0fb;
  border-radius: 4px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  background: #fff;
  z-index: 1005;
  min-width: 200px;
  // min-height: 100px;
  white-space: break-spaces;

  &:before {
    content: "";
    right: -10px;
    border-color: transparent transparent transparent #18a0fb;
    border-style: solid;
    border-width: 7px 0 7px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  li {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    line-height: 1.6;

    &:hover {
      background: #f8f9fa;
    }
  }
}

.settingsIcon {
  transform: rotate(90deg);
  cursor: pointer;
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background-color: #f1f2f4;
  }

  &.active {
    background-color: #18a0fb;
    color: #fff;
    font-size: 12px;
  }
}

.countBar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #2495e1;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
}

.progress {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  text-transform: initial;
  min-width: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &_bar {
    height: 4px;
    width: 200px;
    background: #c4c4c4;
    border-radius: 20px;
    position: relative;
    text-align: center;
    margin-top: 5px;
  }

  &_value {
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    background: #18a0fb;
    border-radius: 20px;
  }
}
