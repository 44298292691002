.servicesPage {
  color: #29394d;
  padding: 2rem 10%;
}

.header {
  margin-bottom: 3rem;
  h1 {
    font-weight: 500rem;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }
  span {
    font-size: 0.875rem;
    color: #6d7f92;
  }
}
.bubbles {
  position: fixed;
  top: 16rem;
  right: -134px;
  color: #29394d;
  font-weight: 500;
  font-size: 16px;
  z-index: 2;
  transition: 0.6s all ease-in-out;

  & > div {
    background: #fff;
    box-shadow: 0px 4px 5px rgba(60, 78, 100, 0.2);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    position: relative;
    width: 205px;
    height: 62px;
    border-radius: 31px 0px 0px 31px;
    padding: 0 1rem;
    transform: translate(0);

    font-size: 16px;
    line-height: 19px;

    &.disabled {
      cursor: initial;
      color: #ccc;

      &:after {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        content: "";
        border-radius: 31px 0px 0px 31px;
        background: rgba(41, 57, 77, 0.1);
      }
    }

    i {
      font-size: 1.5rem;
      z-index: 2;
      padding: 0 0.1rem 0 0.3rem;
    }

    span,
    div {
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
    }

    &:not(.disabled):hover,
    &.active {
      transform: translate(-110px);
      background: #2495e1;
      box-shadow: 0px 4px 5px rgba(109, 127, 146, 0.2);
      color: #fff;
      width: 220px;

      span,
      div {
        opacity: 1;
      }
    }
  }
}

.typesList {
  margin-top: 1.5rem;
  padding-right: 1rem;
  .type {
    cursor: pointer;
    height: 2.5rem;
    padding: 0 0.75rem;
    background: #fff;
    border-radius: 5px 5px 0px 0px;
    font-weight: 500;
    font-size: 0.875rem;
    color: #6d7f92;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #18a0fb;
    .name {
      display: block;
      max-width: 83%;
    }
    &.active {
      background-color: #18a0fb;
      color: #fff;
    }
  }
}
.top {
  padding-bottom: 1rem;
}
.addButton {
  margin-left: auto;
  cursor: pointer;
  background: #fff;
  color: #18a0fb;
  display: flex;
  align-items: center;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #18a0fb;
  padding: 0.5rem;
  svg {
    margin-right: 1rem;
  }
  &_small {
    font-size: 0.75rem;
    padding: 0.25rem;
    svg {
      margin-right: 0.5rem;
    }
  }
}

.right {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #18a0fb;
  padding: 0.5rem;
}
.specialty {
  padding: 0 0.5rem;
  border-right: 1px solid #d8dde2;
  margin-bottom: 1rem;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    i {
      font-size: 24px;
    }
    .name {
      font-weight: 500;
      font-size: 0.875rem;
      display: block;
      margin-left: 0.5rem;
    }
  }
}
.specialtiesList {
  .subSpec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f1f2f4;
    font-size: 0.75rem;
    &:last-child {
      border-bottom: none;
    }
  }
}
.dropDownContainer {
  position: relative;
}
.menuDropdown {
  position: absolute;
  right: 2.7rem;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #18a0fb;
  border-radius: 4px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  background: #fff;
  z-index: 998;
  width: 200px;

  &:before {
    content: "";
    right: -10px;
    border-color: transparent transparent transparent #18a0fb;
    border-style: solid;
    border-width: 7px 0 7px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  li {
    position: relative;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    line-height: 1.6;
    color: #29394d;
    &:hover {
      background: #f8f9fa;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
.cellIcon {
  transform: rotate(90deg);
  cursor: pointer;
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &.active {
    background-color: #18a0fb;
    color: #fff;
    font-size: 12px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.noBorder {
  border-right: none;
}
