.list {
  margin: 1rem 0;
  max-height: 800px;
  overflow: scroll;
}

.faq_card {
  width: 100%;
  border-radius: 4px;
  padding: 1rem 0.5rem;
  border: 1px solid #000;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
  background-color: #1818181d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  &_hide {
    opacity: 0.7;
  }
}

.title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  width: 75%;
}
.actions {
  display: flex;
  align-items: center;
  .icon {
    cursor: pointer;
    margin-left: 0.5rem;
    border: 1px solid #000;
    border-radius: 100%;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
      height: 14px;
      width: 14px;
    }
    &_hide::after {
      content: " ";
      border-left: 2px solid #000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 47%;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
