.widgets {
  position: fixed;
  right: 20px;
  bottom: 85px;
  z-index: 1;

  &_box {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 32px;
  }
}

.emptyPage {
  min-height: 60vh;
  padding-top: 2rem;

  &_title {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 40px;
    line-height: 32px;
  }

  &_help {
    margin: 2rem 0;
  }
}
