.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(41, 57, 77, 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;

  &.hide {
    display: none;
  }
}

.sidebar {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 65%;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: 0.5s all ease-in-out;
  padding: 2rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  &.display {
    transform: translateX(0);
    box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #29394d;
  margin-bottom: 2rem;
}

.close {
  text-align: right;
  cursor: pointer;

  i {
    color: #29394d;
  }
}

.isMulti,
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.actions {
  margin-top: auto;
  margin-bottom: 0;
}

.selectIconArea {
  background-color: #f1f2f4;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.selectIcon {
  font-weight: 500;
  border: 1px dashed #ccc;
  color: #6d7f92;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 6.5rem;
  text-align: center;
  font-size: 0.75rem;
  i {
    font-size: 3rem;
    color: #18a0fb;
    opacity: 0.7;
  }
  p {
    margin: 0;
    font-size: 0.825rem;
    margin-top: 0.5rem;
    font-weight: 600;
    color: #18a0fb;
    text-transform: capitalize;
  }
  &.icon-selected {
    border: none;
    span {
      display: none;
    }
    &:hover {
      p {
        display: none;
      }
      border: 2px dashed #ccc;
      span {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 39.9375em) {
  .sidebar {
    left: 0%;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .sidebar {
    left: 30%;
  }
}
