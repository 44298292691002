.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1005 !important;
  background-color: rgba(41, 57, 77, 0.4);
}
.modalContentAfterOpen {
  .modal {
    top: 0;
    opacity: 1;
  }
}
.modalContentBeforeClose {
  .modal {
    top: 50%;
    opacity: 0;
  }
}
.modalContent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
  background: transparent;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;

  width: 500px;
  opacity: 0;
  background: #f8f9fa;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  height: 60px;
  border-radius: 5px 5px 0px 0px;
  font-size: 22px;
  line-height: 26px;
  color: #2495e1;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.body {
  padding: 2rem;
  overflow: scroll;
  height: 500px;
}

.controls {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem 1rem;
}

.dropZone {
  position: relative;
  width: 100%;
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #3c4e64;
  box-sizing: border-box;
  border-radius: 7.5px;
  font-size: 14px;
  padding: 0 2rem;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.uploading_cover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background: #ffffffb1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errors {
  margin: -1rem 0 1rem !important;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: #f8d7db;
  border: 1px solid #721c24;
  color: #721c24;
  li {
    margin-left: 1rem;
    margin-bottom: 0.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
