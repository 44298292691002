.list {
  margin: 0 -10px;
  margin-top: 1rem;
  max-height: 400px;
  overflow: scroll;
}

.cardCont {
  padding: 0 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.service_card {
  width: 100%;
  border-radius: 4px;
  height: 10rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  position: relative;
  &_hide {
    opacity: 0.7;
  }
}

.title {
  color: #fff;
  margin-top: auto;
  font-weight: 700;
  font-size: 1rem;
  line-height: 120%;
}
.actions {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  .icon {
    cursor: pointer;
    margin-left: 0.5rem;
    background-color: #181818;
    border-radius: 100%;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
      height: 14px;
      width: 14px;
    }
    &_hide::after {
      content: " ";
      border-left: 2px solid #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 47%;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
