.pageTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.pageHelpTitle {
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  margin-bottom: 1.5rem;
}

.form {
  background: #ffffff;
  border: 1px solid #18a0fb;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  margin-bottom: 2rem;
  position: relative;

  &_content {
    padding: 10px 20px 20px;
    height: 400px;
    overflow: scroll;

    &_pad {
      padding: 30px 20px 20px;
    }

    &.loading {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  &_content_settings {
    padding: 20px;
    height: fit-content;
  }
  &_title {
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    margin-bottom: 10px !important;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 1rem !important;
  padding: 20px;

  .tab {
    position: relative;
    width: 50%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #6d7f92;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;

    &.active,
    &:hover {
      color: #18a0fb;
      &:after {
        background-color: #18a0fb;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      background-color: #b2bcc6;
      border-radius: 50px;
    }
  }
}

.actionBar {
  border-top: 1px solid #f1f2f4;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);

  > div {
    display: flex;
    justify-content: space-between;
  }
}
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);

  button {
    min-width: 114px;
    justify-content: center;
  }
}

// List result
.listContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
}
.tableContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  height: max-content;
  padding-bottom: 15px;
}
.table {
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;
    i {
      cursor: pointer;
      color: #6d7f92;

      &.iconActive {
        color: #29394d;
      }
    }

    .sortBox {
      margin-left: 5px;
    }
  }

  tr {
    background: #fafbfb;
    border-bottom: 2px solid #fff;
    border-radius: 2px;
  }

  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;

    &.centerCell {
      text-align: center;
    }

    .cellIcon {
      transform: rotate(90deg);
      cursor: pointer;
      font-size: 14px;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: #f1f2f4;
      }

      &.active {
        background-color: #18a0fb;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}

// .dropDownContainer {
//   position: relative;
// }
// .menuDropdown {
//   position: absolute;
//   left: 2.4rem;
//   top: 100%;
//   box-shadow: 0 4px 4px rgba(41, 57, 77, 0.1);
//   border-radius: 0 0 4px 4px;
//   margin: 0;
//   opacity: 0;
//   visibility: hidden;
//   transition: all 0.2s ease-in-out;
//   background: #fff;
//   z-index: 1005;
//   width: 200px;

//   li {
//     position: relative;

//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
//     padding: 10px;
//     line-height: 1.6;

//     &:hover {
//       background: #f8f9fa;
//     }
//   }

//   &.show {
//     opacity: 1;
//     visibility: visible;
//   }
// }

.inputSearch {
  display: flex;
  background: #fff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  border-radius: 30px;
  height: 32px;
  width: 300px;
  align-items: center;

  &_icon {
    color: #b7bdc3;
    cursor: pointer;
    font-size: 1.2rem;
    display: inherit;
    margin: auto 1rem;
  }

  input {
    width: 240px;
    border: none;
    outline: none;
    margin: 0;
    color: #3c4e64;
  }
}

.phoneGroup {
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 9px 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .errorSpan {
    color: #d50000 !important;
    font-size: 12px;
    display: block;
    text-align: right;
    line-height: 16px;
    margin-top: 4px;
  }
}
.uen {
  display: flex;
  align-items: center;
  input {
    margin-left: 0.25rem;
    border: none;
    color: #29394d;
    background-color: transparent;
  }
}

.lockIcon {
  color: #ef8318;
  font-size: 1.25rem;
  text-align: center;
}
.dropDownContainer {
  position: relative;
}
.menuDropdown {
  position: absolute;
  left: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #18a0fb;
  border-radius: 4px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  background: #fff;
  z-index: 1005;
  width: 200px;

  &:before {
    content: "";
    left: -10px;
    border-color: transparent transparent transparent #18a0fb;
    border-style: solid;
    border-width: 7px 0 7px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }

  li {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    line-height: 1.6;

    &:hover {
      background: #f8f9fa;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
.checkbox {
  height: 1.125rem;
  width: 1.125rem;
  background: #b2bcc6;
  border: 2px solid #fff;
  border-radius: 4px;
  cursor: pointer;
}
.wrapCheckbox {
  display: flex;
  align-items: center;
  min-width: 100px;
  span {
    margin-left: 4px;
  }
}
.checkbox_active {
  background: #18a0fb;
}

.groupName {
  display: block;
  width: max-content;
  border: 1px solid #b2bcc6;
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  float: left;
}

.inputGroup {
  display: flex;
  background: #fff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  border-radius: 30px;
  height: 32px;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  input {
    width: 90%;
    border: none;
    outline: none;
    margin: auto;
    color: #3c4e64;
  }
  .searchResults {
    margin-top: 0.25rem;
    position: absolute;
    top: 100%;
    left: 5%;
    background-color: #fff;
    width: 90%;
    border: 1px solid #b2bcc6a3;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    li {
      padding: 0.5rem;
      cursor: pointer;
      &:hover {
        background-color: #f3faff;
      }
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.groups {
  margin-bottom: 1rem;
  min-height: 32px;

  span {
    float: left;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}

.groupFilter {
  margin-bottom: 0.75rem;
  width: 38rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .select {
    position: relative;
    margin-right: 0.625rem;
    display: flex;
    align-items: center;
    .refresh {
      padding: 0rem 0.2rem;
      width: fit-content;
    }
    button {
      width: 15rem;
      height: 2.125rem;
      background: #f8f9fa;
      border: 1px solid #b2bcc6;
      border-radius: 4px;
      padding: 0rem 0.813rem 0rem 0.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 0.75rem;
      cursor: pointer;
    }
    .active_btn {
      background: #ffffff;
      border: 1px solid #18a0fb;
      svg {
        transform: rotate(180deg);
      }
    }
    .list {
      width: 15rem;
      max-height: 14rem;
      position: absolute;
      left: 0;
      top: 2.125rem;
      background: #ffffff;
      border: 1px solid #b2bcc6;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 4px;
      z-index: 10;
      margin: 0;
      display: flex;
      flex-direction: column;
      input {
        width: 100%;
        background: #f8f9fa;
        border: 1px solid #b2bcc6;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 9px 15px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
      ul {
        overflow: scroll;
      }
      .selected {
        background-color: #18a0fb;
        color: #fff;
        &:hover {
          background-color: #18a0fb;
          color: #fff;
        }
      }
      .disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      li {
        font-weight: 400;
        font-size: 0.75rem;
        height: 1.875rem;
        padding-left: 1.313rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #f3faff;
        }
      }
    }
  }
}
.groupList {
  display: flex;
  align-items: center;
  color: #6d7f92;
  margin-bottom: 0.5rem;
  span {
    margin-right: 0.5rem;
    line-height: 16px;
    font-size: 14px;
  }
  li {
    font-size: 12px;
    padding: 0.1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: left;
    svg {
      margin-top: 7px;
      margin-left: 0.5rem;

      cursor: pointer;
    }
    &:hover {
      background-color: #18a0fb;
      color: #fff;
    }
  }
}
.tabForms {
  display: flex;
  margin: 0 -20px 20px -20px !important;
  padding: 0;
  .active {
    color: #18a0fb;
  }

  li {
    height: 26px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #6d7f92;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-left: 1px solid #d8dde2;
    cursor: pointer;
    &:first-child {
      border: none;
    }
  }
}

.titles {
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #29394d;
    margin-bottom: 10px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    margin-bottom: 10px;
  }
}
.left {
  margin-left: auto;
  width: fit-content;
  button {
    display: block;
  }
}

.pasteBox {
  background-color: #f8f9fa;
  border: 1px solid #b2bcc6;
  min-height: 100px;
  margin-bottom: 2rem;
  position: relative;
  padding: 10px;
  border-radius: 4px;

  &_close {
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .load {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  .spinner {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-width: 2px;
    border-color: #b2bcc6;
    border-top-color: #18a0fb;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
}

.paste_content {
  display: flex;
  gap: 10px;
}
.paste_left,
.paste_right {
  width: 50%;
  background-color: #fff;
  border: 1px solid #b2bcc6;
  border-radius: 4px;
  overflow: scroll;
  min-height: 150px;
  max-height: 200px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
.paste_items {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.paste_item {
  font-size: 12px;
  line-height: 14px;
  background-color: #e1e4e8;
  padding: 4px 6px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.paste_right .paste_item {
  background-color: #fcb776;
}
.paste_copy {
  cursor: pointer;
}
.paste_add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border: 1px solid #6d7f92;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
