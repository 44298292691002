.modal {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(41, 57, 77, 0.6);
  z-index: 1010;

  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.modalOverlayAfterOpen {
  opacity: 1;
}

.modalOverlayBeforeClose {
  opacity: 0;
}

.modalContent {
  position: absolute;
  top: 0;
  left: 0;
  right: -100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  overflow: none;
  outline: none;
  padding: 0;

  transition: right 300ms ease-in-out;
}

.modalContentAfterOpen {
  right: 0;
}

.modalContentBeforeClose {
  right: -100%;
}

.close {
  position: absolute;
  right: 23px;
  top: 27px;
  width: 17px;
  height: 17px;
  z-index: 1;
  cursor: pointer;
  color: #f1f2f4;
}

.body {
  width: 60%;
  height: 100%;
  margin-left: auto;
  background-color: #29394d;
  color: #ffffff;
  padding: 87px 56px 0 56px;
  overflow: scroll;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-content: flex-start;
  min-height: 0px;
  min-width: 0px;
}

.options {
  display: flex;
  justify-content: space-around;
  width: 100%;
  min-height: 42px;
  margin-bottom: 32px;

  label {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    font-style: normal;
    font-weight: normal;
    max-width: 50%;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    user-select: none;

    input {
      width: 23px;
      height: 23px;
      margin-bottom: 0;
      margin-right: 24px;
      opacity: 0;
      visibility: hidden;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 23px;
      height: 23px;
      background: #6d7f92;
      // background: #18A0FB;
      border: 3px solid #f1f1f1;
      box-sizing: border-box;
      border-radius: 50%;
      cursor: pointer;
    }

    span {
      width: 100%;
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 155%;
      color: #ffffff;

      padding-left: calc(23px + 24px);
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .selectedOption {
    &::before {
      background: #18a0fb;
    }
  }
}

.dzContent {
  position: relative;
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #3c4e64;
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 7.5px;

  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 155%;
  text-align: center;
  color: #ffffff;
  user-select: none;

  svg {
    margin-top: 40px;
    margin-bottom: 18px;
    color: #ffffff;
  }

  button {
    width: 256.88px;
    height: 41.28px;
    margin: 22px 0;

    background: #18a0fb;
    border: 0.5px solid #18a0fb;
    box-sizing: border-box;
    border-radius: 4px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;

    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}

.dropzoneActive {
  border-color: #18a0fb;
}

.dropzoneReject {
  border-color: #dc3545;
}

.dropzoneTextLarge {
  margin: 0 24px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 155%;
  text-align: center;
  color: #ffffff;
}

.dropzoneTextSmall {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 155%;
  text-align: center;
  color: #ffffff;
}
.title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1.5rem 0 1rem 0;
}
.mediasList {
  width: 100%;
  margin-bottom: 1rem;
}
.actions {
  width: 100%;
  margin: 1rem 0 1.5rem 0;
  display: flex;
  justify-content: flex-end;
}
