.history {
  padding: 6.25rem 2.5rem;
  display: flex;
  align-items: flex-start;
}
.left {
  margin-right: 5rem;
  .section_title {
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 1rem;
  }
  .title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    min-width: 12rem;
  }
}
.right {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (max-width: 1024px) {
  .history {
    padding: 3.75rem 1.25rem;
    flex-direction: column;
    align-items: center;
  }
  .left {
    margin: 0;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .section_title {
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 1rem;
    }

    .title {
      width: 100%;
      font-size: 2.5rem;
      text-align: center;
    }
  }
  .right {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 150%;
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
}
