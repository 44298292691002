.servicesPage {
  color: #29394d;
  padding: 2rem 10%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  h1 {
    font-weight: 500rem;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }
  span {
    font-size: 0.875rem;
    color: #6d7f92;
  }
}

.list {
  margin: 0 -7px;
}
.cardCont {
  padding: 0 7px;
  margin-bottom: 14px;
}

.positionPopOver {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
