.title {
  margin: 2rem 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.space {
  margin: 2rem 0;
}

.list {
  li {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
  }

  i {
    margin-right: 10px;
    background-color: #fff;
    border-radius: 50%;
    color: #18a0fb;
    font-size: 12px;
    padding: 3px;
    width: 18px;
    height: 18px;
  }
}

.footer {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  padding: 0 4rem;
}

@media screen and (max-width: 1024px) {
  .footer {
    position: relative;
    padding: 0;
    bottom: 0;
    margin: 3rem 0 1rem;
  }
}
