.services_section {
  padding: 2rem 1.25rem;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services_title {
  font-weight: 600;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1rem;
  text-align: center;
}

.description {
  font-size: 1rem;
  line-height: 120%;
  text-align: center;
}

.list {
  width: 100%;
  margin-top: 2rem;
  margin-left: -8px;
  margin-right: -8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0;
  .cardCont {
    padding: 0 8px;
    margin-bottom: 16px;
    .card {
      .card_img {
        height: 8rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 1rem;
      }
      .card_title {
        font-size: 1rem;
        font-weight: 700;
        line-height: 140%;
        margin-bottom: 0.5rem;
        text-align: left;
        width: 100%;
      }
      .card_description {
        line-height: 150%;
        text-align: left;
        width: 100%;
      }
    }
  }
}
