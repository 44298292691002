.searchBar {
  display: flex;
  align-items: center;
  margin:0 110px 20px;
  li {
    width: 32px;
    letter-spacing: 0.02em;
    border-radius: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: #F1F2F4;
    border: 1px solid #D8DDE2;
    &:hover,
    &.selected {
      border:none;
      cursor: pointer;
      font-weight: 500;
      background: #18A0FB;
      color: #F1F2F4;
       border-radius: 40px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
}
.filterBar {
    margin: 0 50px 10px;
    > ul {
      width: 100%;
      overflow-x: scroll;
      padding-bottom: 12px;
      li {
        padding: 9px 18px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #6D7F92;
        background: #F1F2F4;
        border-radius: 30px;
        &:hover,
        &.selected {
          cursor: pointer;
          background: #18A0FB;
          color: #F1F2F4;
        }
      }

    }
    ul {
      display: flex;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        color: #6d7f92;
        box-sizing: border-box;
        text-align: center;
        text-transform: uppercase;
        margin: 0 2px;
        white-space: nowrap;
    
       
      }
    }
}
.searchBox {
    flex: 1;
    display: flex;
    background: #ffffff;
    border: 1px solid #e8eaed;
    box-sizing: border-box;
    border-radius: 30px;
    height: 32px;
    align-items: center;
    margin-right: 15px;
    padding-left: 15px;
  
    i {
      color: #b7bdc3;
      cursor: pointer;
      font-size: 1rem;
      display: inherit;
      margin: 0 10px
    }
  
    input {
      width: 100%;
      border: none;
      outline: none;
      margin: 0;
      color: #3c4e64;
      margin-right: 10px;
    }
}

 