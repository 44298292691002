.emptyPage {
  min-height: 60vh;
  padding-top: 2rem;

  &_title {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 40px;
    line-height: 32px;
  }

  &_help {
    margin: 2rem 0;
  }
}
