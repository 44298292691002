/* reset css  */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  color: $new-title-color;
  font-family: $font-family-base;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  background: $body-bg;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ttp-label-lg {
  font-size: 14px !important;
  line-height: 16px !important;
}
.ttp-label {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 9px 15px;
  .star {
    color: #18a0fb;
    margin-left: 4px;
  }
}
.ttp-cell-input {
  background: #fff;
  border: none;
  box-sizing: border-box;
  padding: 9px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;

  &:focus {
    border-color: #18a0fb;
  }
}
.ttp-input-lg {
  background: #f8f9fa;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 15px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;

  &:focus {
    border-color: #18a0fb;
  }
}
.ttp-input,
.location-search-input {
  background: #f8f9fa;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 9px 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;

  &:focus {
    border-color: #18a0fb;
  }

  &.error {
    border-color: #d50000;
  }
}
.ttp-error {
  color: #d50000;
  font-size: 12px;
  display: block;
  text-align: right;
  line-height: 16px;
  margin-top: 4px;
}
.autocomplete-dropdown-container {
  position: absolute;
  background: white;
  width: 100%;
  z-index: 9;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  line-height: 14px;
  border: 1px solid #eee;

  .suggestion-item,
  .suggestion-item--active {
    padding: 10px;
  }
}
.group-input {
  position: relative;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.ttp-form-group {
  margin-bottom: 16px;
}

.ttp-form-group-h {
  min-height: 90px;
}

.icon-picture i:before {
  font-size: 25px;
}

.news-block {
  margin-top: 60px;
  margin-bottom: 60px;
}

// override slick
.slick-next,
.slick-prev {
  top: 50% !important;
  background: #fff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  /* shadow bouton */

  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 50px;

  &:hover {
    background: #fff;
  }
  &::before {
    font-family: "tamtam" !important;
    content: "\e929";
    color: #29394d;
    font-size: 20px;
  }
}
.slick-next {
  right: -6px;
}
.slick-prev {
  left: -6px;
}
.slick-next:before {
  transform: rotate(180deg);
  display: inline-block;
}
.d-flex {
  display: flex;
  align-items: center;
}
.w-100 {
  width: 100%;
}
.mx-2 {
  margin-left: 20px;
  margin-right: 20px;
}
.ml-2 {
  margin-left: 20px;
}
.portail-slider .slick-list {
  height: 20rem;
}
.react-select__clear-indicator {
  padding: 0 !important;
}
