@import "/src/styles/base/variables";

.event {
  // height: 22.5rem;
  width: 100%;
  height: 100%;
  // margin: 0 0.5rem 1rem;
  font-size: 0.75rem;
  border-radius: 5px;
  padding: 0.625rem;
  background: #fff;
  color: #29394d;
  border: 1px solid #f1f2f4;
  h3 {
    font-size: 1rem;
    font-weight: 500;
    height: 2.75rem;
    overflow: hidden;
    margin-bottom: .25rem;
  }
  p {
    margin: 0;
  }
  h4 {
    font-size: .75rem;
    color: #6d7f92;
    margin-bottom: .25rem;
  }
  &.fromCycle {
    h3 {
      margin-bottom: .5rem;
    }
    .controls {
      margin-top: 1.625rem;
    }
  }
  &.mine {
    background: #edfaf5;
    border-color: #dcf5ec;
    h3 {
      margin-bottom: 1rem;
    }
    h4 {
      margin-bottom: .5rem;
    }
    .infos {
      height: auto;
      margin-bottom: 1.625rem;
    }
  }
}
.noMargin {
  margin-left: 0;
}
.eventContent {
  height: 100%;
}
.banner {
  width: 100%;
  height: 8.75rem;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  display: flex;
  position: relative;
}
.actions {
  padding: 0 0.5rem;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  position: relative;
  top: -11px;
  
  .btn {
    width: 9.25rem;
    height: 2rem;
    border-radius: 4px;
    box-shadow: $new-box-shadow;
    color: #fff;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.blue {
      background-color: $portal-new-blue;
    }
    &.expiresBtn {
      background-color: $portal-new-red;
    }
  }
}
.eventType {
  background-color: #fff;
  padding: 0 1rem;
  height: 1.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .25rem;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 500;
  .barred {
    position: relative;
    margin-left: .5rem;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background: #fe3745;
      top: 40%;
      left: 0;
      transform: rotate(-25deg);
    }
  }
}
.infos {
  height: 100px;
  font-size: .75rem;
  overflow: scroll;
  li {
    display: flex;
    margin-top: 0.25rem;
    &:first-child {
      margin-top: 0;
    }
    span {
      line-height: 1.25;
    }
  }
  i {
    margin-right: 0.5rem;
    font-size: 0.75rem;
    display: flex;
    margin-top: 0.125rem;
  }
  .pending {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fe3745;
    box-shadow: 0 0 2px 2px rgba(254, 55, 69, .3);
  }
}
.communityLogo {
  margin: .5rem;
  width: 3.5rem;
  height: 2.25rem;
  background-color: rgba(255, 255, 255, .85);
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: .2rem;
  span {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
  }
}
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .625rem;
  a {
    width: 49%;
  }
}
.btn {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
}
.btnPrimary {
  color: #fff !important;
  background-color: #06d9b1;
  &:hover {
    background-color: #02af8e;
  }
}
.btnSecondary {
  color: #6d7f92 !important;
  background-color: #f1f2f4;
  &:hover {
    background-color: #d8dde2;
  }
}
.btnBlue {
  color: #fff !important;
  background-color: #18a0fb;
  &:hover {
    background-color: #0980cf;
  }
  i {
    margin-right: .5rem;
  }
}
.btn100 {
  width: 100% !important;
}
.lable {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 0 .75rem;
    background: #18a0fb;
    border-radius: 50px;
    font-size: .75rem;
    font-weight: 400;
    color: #fff;
    position: absolute;
    bottom: 1rem;
    right: .5rem;
    i {
      margin-right: .5rem;
    }
}
.bagde {
    // background-image: url(/img/badge.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: .25rem;
    right: .25rem;
    width: 36px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}