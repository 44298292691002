.content {
  padding: 20px 12px;
  text-align: left;
}
.pack {
  flex-shrink: 0;

  &_top {
    padding: 10px 12px;
    color: #c7e8fe;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: break-spaces;
    background-image: url("../../../public/img/effect_pack.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #28394e;
    border: 1px solid #28394e;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_bottom {
    padding: 10px;
    min-height: 300px;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    border: 1px solid #29394d;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &_status {
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 8px 12px;
    color: #fff;
  }
}

.enabled {
  background-color: #02af8e;
}

.disabled {
  background-color: #eb5757;
}

.pack_actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pack_btn {
  flex: 1;
  padding: 12px;
  margin: 12px 0;
  text-align: center;
  border: 1px solid #18a0fb;
  color: #18a0fb;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    background-color: #f3faff;
  }
}
