.formula {
  min-height: 220px;
  border: 1px solid #18a0fb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_top {
    flex: 1;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #18a0fb;
    background-color: #f3faff;
    padding: 14px;
    text-transform: capitalize;
    border-radius: 8px 8px 0 0;
    position: relative;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      display: block;
    }
  }

  &_status {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px !important;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 4px 8px;
    color: #fff;
  }

  &_actions {
    display: none;
    position: absolute;
    top: 50px;
    right: 10px;
    gap: 10px;
  }

  &_bottom {
    display: flex;
  }

  &_price {
    flex: 1;
    padding: 14px;
    border-top: 1px solid #18a0fb;

    &_left {
      border-right: 1px solid #18a0fb;
    }
  }

  &:hover {
    .formula_actions {
      display: flex !important;
    }
  }
}

.btn {
  border: none;
  border-radius: 50%;
  background-color: #18a0fb;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;

  &_red {
    background-color: #f4515d;
  }
}

.enabled {
  background-color: #02af8e;
}

.disabled {
  background-color: #eb5757;
}

.price {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  gap: 5px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}
.price_label {
  color: #6d7f92;
  margin-bottom: 10px;
}
.price_member {
  color: #de4848;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  display: flex;
  align-items: center;
  gap: 5px;

  .barred {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-decoration: line-through;
  }
}

.empty {
  min-height: 220px;
  border: 1px solid #18a0fb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #18a0fb;
  background-color: #f3faff;
  cursor: pointer;
  // font-size: 22px;
}
