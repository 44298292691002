.stats_section {
  padding: 2rem 1.25rem;
  color: #000;
  display: flex;
}

.img {
  flex-grow: 1;
  height: 20rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right {
  width: 20rem;
  margin-left: 2rem;
  padding-top: 2rem;
}

.right_title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1rem;
}

.description {
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 2rem;
}

.list {
  margin: 0 -8px;
}

.cardCont {
  padding: 0 8px;
  margin-bottom: 16px;
}

.card_title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 0.5rem;
}

.card_description {
  font-size: 1rem;
  line-height: 120%;
}

.v2 {
  .img {
    display: none;
  }
  .right {
    margin-left: 0;
    padding-top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    .top {
      width: 20rem;
      margin-right: 2rem;
      .description {
        margin-bottom: 0;
      }
    }
    .list {
      flex-grow: 1;
    }
  }
}
