.container {
  padding: 2rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_title {
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1.5rem;
  text-align: center;
}

.description {
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
}
.grid {
  columns: 12rem;
  gap: 1rem;
  counter-reset: grid;
  justify-items: center;
  align-items: center;
}

.item + .item {
  margin-top: 1rem;
}

.item {
  break-inside: avoid;
  aspect-ratio: 4 / 3;
  background: #dbdbdb;
  justify-items: center;
}

.item::before {
  counter-increment: grid;
}

.item:nth-child(5) {
  aspect-ratio: 1 / 2;
  display: flex;
  align-items: center;
}
