.title {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #3c4e64;
    margin-top: 2rem;
  }
  
  .blog_box {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 1rem 0 !important;
  
    &_item {
      flex: 1;
      border: 1px solid #d8dde2;
      border-radius: 8px;
      padding: 1.25rem;
      min-height: 150px;
  
      &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
  
        h3 {
          color: #3c4e64;
        }
      }
    }
  }