.styles-module_carousel-base__3keqD  div>button[data-direction=left], 
.styles-module_carousel-base__3keqD  div>button[data-direction=right] {
    background: url(https://cdn.dsmcdn.com/web/production/slick-arrow.svg) 50% no-repeat;
    border: none;
    cursor: pointer;
    height: 80px;
    outline: 0;
    position: relative;
    top: 80px;
}
.styles-module_carousel-base__3keqD div>button[data-direction=right] {
    right: -16px;
}

.styles-module_carousel-base__3keqD div>button[data-direction=left] {
    left: -16px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.styles-module_sliderBase__swkx1 >div[data-arrow=left]>button {
    background: url(https://cdn.dsmcdn.com/web/production/slick-arrow.svg) 50% no-repeat;
    border: none;
    cursor: pointer;
    height: 40px;
    left: -13px;
    outline: 0;
    -webkit-transform: rotate(180deg) scale(.5);
    transform: translateY(50%) rotate(180deg) scale(.5);
    top: 50%;
}

.styles-module_sliderBase__swkx1 >div[data-arrow=right]>button {
    background: url(https://cdn.dsmcdn.com/web/production/slick-arrow.svg) no-repeat;
    border: none;
    cursor: pointer;
    height: 40px;
    outline: 0;
    position: absolute;
    right: -16px;
    -webkit-transform: scale(.5);
    transform: translateY(-50%) scale(.5);
    top: 50%;
}
