.content {
  padding: 1.5rem 2rem;
}
.backNav {
  background-color: #fafbfb;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  height: 50px;

  button {
    cursor: pointer;
    height: 2.25rem;
    padding: 0px 1.188rem;
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-right: 1.188rem;

    svg {
      margin-right: 0.688rem;
    }
  }

  &_title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #18a0fb;

    span {
      color: #000;
    }
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e1e4e8;
  font-size: 12px;
  line-height: 14px;

  &_hours {
    border-left: 1px solid #e1e4e8;
    margin-left: 2rem;
    padding-left: 2rem;
  }

  &_value {
    font-weight: 500;
  }
}

.bloc {
  width: 100%;
  margin-bottom: 2rem;

  tr {
    border-bottom: 1px solid #e1e4e8;
  }

  th,
  td {
    padding: 10px 20px;
    font-size: 12px;
    line-height: 14px;
  }

  th {
    text-align: left;
    font-weight: 500;
  }

  .year {
    vertical-align: top;
    width: 60px;
    font-weight: 500;
    border-right: 1px solid #29394d;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: center;

    &_hours {
      margin-top: 20px;
    }

    &_label {
      display: block;
      font-weight: 400;
      margin-bottom: 8px;
    }
  }

  .date {
    span {
      display: block;
    }
  }

  .duration {
    display: flex;
    align-items: center;

    &_value {
      margin-left: 5px;
      span {
        display: block;
      }
    }
  }
}

.no_data {
  text-align: center;
  margin: 4rem;
}
