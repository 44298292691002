.banner {
  padding: 0 2rem;
  height: 17.375rem;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}

.subtitle {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 150%;
}
