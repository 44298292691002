.category {
    height: 108px;
    display: "flex";
    justify-content: "center";
    align-items: "center";
    color: 'white';
    border: 1px solid #F1F2F4;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 0 12px;
    a {
        color: #fff;
        max-width: 130px;
        text-align: center;
    } 
}
.chain {
    width: 184px;
    height: 108px;  
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #F1F2F4;
    box-sizing: border-box;
    border-radius: 5px; 
}