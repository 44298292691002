.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(41, 57, 77, 0.4);
    z-index: 999;
    width: 100%;
    height: 100%;

    &.hide {
        display: none;
    }
}

.sidebar {
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transform: translateX(100%);
    transition: 0.5s all ease-in-out;
    padding: 2rem;
    overflow-y: scroll;

    &.display {
        transform: translateX(0);
        box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
    }
}

.sidebarPage {
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 30%;
    right: 0;
    z-index: 1000;
    transform: translateX(100%);
    transition: 0.5s all ease-in-out;
    padding: 2rem;
    overflow-y: scroll;

    &.display {
        transform: translateX(0);
        box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
    }
}

.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #29394d;
    margin-bottom: 2rem;
}

.close {
    text-align: right;
    cursor: pointer;
}

.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: #29394d;
}

.search {
    width: 14rem;
    height: 2rem;
    border: 1px solid #d8dde2;
    border-radius: 30px;
    display: flex;
    align-items: center;

    input {
        margin-bottom: 0;
        height: 100% !important;
        width: 89%;
        border: none !important;
        outline: none !important;
        margin-left: 0.8rem !important;
        box-shadow: none;
        background-color: transparent;
    }
}

@media screen and (max-width: 39.9375em) {
    .sidebar {
        left: 0%;
    }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .sidebar {
        left: 30%;
    }
}

.cardItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 5.5rem;
    min-height: inherit;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;
    padding: 0 0.5rem;
    cursor: pointer;
    color: #6d7f92;
    transition: all 0.1s ease-in-out;
    box-shadow: 0px 0px 4px rgba(41, 57, 77, 0.1);
    margin-bottom: 1rem;
    border-radius: 5px;
    overflow: hidden;

    &:hover {
        color: #18a0fb;
        transform: scale(1.1);
        background-color: #f3faff;
        border: 1px solid #18a0fb;
        .actions {
            opacity: 1;
            right: 4px;
            transform: translateX(0);
        }
    }
    i {
        font-size: 2.5rem;
    }

    span {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.fetching-icons {
    &:hover {
        transform: none;
        background-color: initial;
        border: none;
    }
}
