.backNav {
  height: 60px;
  padding-left: 5.7%;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
  display: flex;
  align-items: center;
  button {
    cursor: pointer;
    height: 2.25rem;
    padding: 0px 1.188rem;
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-right: 1.188rem;

    svg {
      margin-right: 0.688rem;
    }
  }
  span {
    color: #18a0fb;
    font-size: 1rem;
    font-weight: 500;
  }
}
