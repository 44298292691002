.ratesPage {
  color: #29394d;
  padding: 2rem 10%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  h1 {
    font-weight: 500rem;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }
  span {
    font-size: 0.875rem;
    color: #6d7f92;
  }
}

.rateForm {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 10px;

  .price {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #29394d;
  }
}

.appName {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #18a0fb;
  margin-bottom: 20px;
}

.priceRow {
  border-bottom: 1px solid #d8dde2;
  margin-bottom: 20px;
  padding-bottom: 10px;
  color: #6d7f92;
}

.actions {
  display: flex;
  justify-content: space-between;

  &_del {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    color: #fe3745;
    border: 1px solid #fe3745;

    &:hover {
      background-color: #ffd7da;
    }
  }

  &_edit {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    color: #6d7f92;
    border: 1px solid #6d7f92;

    &:hover {
      background-color: #f4f7f9;
    }
  }
}

.activeStatus {
  font-weight: 500;
  color: #06d9b1;
  margin-left: 10px;
}

.disabledStatus {
  font-weight: 500;
  color: #fe3745;
  margin-left: 10px;
}
