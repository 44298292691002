.expertise_section {
  padding: 2rem 1.25rem;
  background-color: #f4f4f4;
  display: flex;
}

.left {
  max-width: 200px;
  margin-right: 2rem;
  .expertise_title {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 1.5rem;
  }
  .description {
    font-size: 0.75rem;
    line-height: 120%;
    margin-bottom: 1rem;
  }
}

.right {
  flex-grow: 1;
  margin: 0 -8px;
  .cardCont {
    padding: 0 8px;
    margin-bottom: 16px;
  }
}

.specialty_card {
  .icon {
    i {
      font-size: 1rem;
    }
  }
  .title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 120%;
    margin: 1rem 0;
  }
  .list {
    li {
      list-style-type: disc;
      margin-bottom: 8px;
      margin-left: 1rem;
    }
    .favorite {
      margin-left: 0;
      background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      list-style: none;
      border-left: 1.5px solid black;
      padding: 0.813rem 0.813rem 0.813rem 1rem;
      font-size: 1rem;
      font-weight: 600;
      line-height: 120%;
    }
  }
}
