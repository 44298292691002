.wraper {
  display: flex;
  border-bottom-width: 1px;
  border-color: #e5e7eb;
  font-size: 0.9rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: black;
  background: white;
  padding: 0;
  margin: 0;
  li {
    padding: 10px;
    align-items: center;
    &.active {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}
.img {
  width: 8rem;
  height: 3.375rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
}
.item {
  display: flex;
  align-items: center;
}
.menu {
  position: relative;
  .group {
    display: flex;
    align-items: center;
  }
  &:hover {
    .subMenu {
      display: block;
    }
  }
}
.subMenu {
  display: none;
  position: absolute;
  z-index: 9;
  white-space: nowrap;
  background: white;
  color: black;
  padding: 0 20px;
}
