@import "/src/styles/base/variables";
.btn {
    border-top: 1px solid #B2BCC6;
    position: relative;
    margin: 70px 0;
    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: $body-bg;
        padding: 0 43px;
    }
    button {
        cursor: pointer;
        padding: 12px 22px;
        background: #FFFFFF;
        border: none;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        border-radius: 50px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #29394D;
        i {
            margin-left: 12px;
        }
    }
}