.servicesPage {
  color: #29394d;
  padding: 2rem 10% 0 10%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  h1 {
    font-weight: 500rem;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }
  span {
    font-size: 0.875rem;
    color: #6d7f92;
  }
}

.typesList {
  margin-top: 1.5rem;
  padding-right: 1rem;
  overflow: scroll;
  height: 490px;
  .type {
    cursor: pointer;
    padding: 0.5rem;
    background: #fff;

    font-weight: 500;
    font-size: 0.875rem;
    // margin-bottom: 0.75rem;
    display: flex;
    border: 1px solid #18a0fb;
    border-bottom: none;
    &:last-child {
      border-bottom: 1px solid #18a0fb;
    }
    .unread {
      display: block;
      height: 0.5rem;
      width: 0.5rem;
      background-color: #18a0fb;
      margin: 0 0.5rem;
      border-radius: 100%;
    }
    .read {
      background-color: transparent;
    }
    .flexColumn {
      width: 85%;
    }
    .name {
      display: block;
      font-size: 1rem;
    }
    .publishedAt {
      display: block;
      font-size: 12px;
      color: #6d7f92;
      margin-top: 0.25rem;
    }
    .arrow {
      margin: auto;
    }
  }
}

.right {
  background-color: #fff;
  border: 1px solid #18a0fb;
  padding: 1rem 1.5rem;
  height: fit-content;
  max-height: 490px;
  overflow-y: scroll;
  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;
    .info {
      display: flex;
      flex-direction: column;
      .item {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        strong {
          margin-right: 0.5rem;
        }
        .isContact {
          margin-left: 0.5rem;
          &_fetching {
            color: #6d7f92;
          }
          &_add {
            color: #18a0fb;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .publishedAt {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      font-size: 14px;
      color: #6d7f92;
    }
  }
  .subject {
    margin-bottom: 1rem;
    line-height: 32px;
  }
}
.selectMessage {
  height: 100%;
  width: 100%;
  text-align: center;
  margin: 3rem 0;
  font-size: 1.5rem;
  color: #6d7f929b;
  font-weight: 600;
}

.search {
  background-color: #fff;
  width: 18.75rem;
  height: 2rem;
  border: 1px solid #d8dde2;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  input {
    width: 86%;
    border: none;
    outline: none;
    margin-left: 0.875rem;
  }
}
.reply {
  display: block;
  width: fit-content;
  margin-bottom: 1rem;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 3rem;
  background: #18a0fb;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}
