.testimonials_section {
  padding: 2rem 1.25rem;
}
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  .section_title {
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 1rem;
  }
  .title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 1rem;
  }
  .description {
    text-align: center;
    font-size: 1rem;
    line-height: 120%;
  }
}

.slider {
  position: relative;
  margin: 0 -8px;
  .cardCont {
    padding: 0 8px;
  }
}

.testimonial_card {
  min-width: 3px;
  padding: 1rem;
  background-color: #f4f4f4;
  border: 1px solid #000;
  .rating {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    svg {
      margin-right: 4px;
    }
  }
  .message {
    font-size: 1rem;
    line-height: 120%;
    margin-bottom: 1rem;
  }

  .author {
    display: flex;
    align-items: center;
    .img {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 1rem;
    }
    .info {
      line-height: 120%;
      .name {
        font-weight: 600;
      }
    }
  }
}

.slider_btn {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  border: 1px solid #000;
  background: #fff;
  position: absolute;
  top: 50%;
  left: -1%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.right_btn {
  left: unset;
  right: -1%;
}

.slick_dots {
  position: relative !important;
  width: fit-content;
  margin: 0 auto !important;
  margin-top: 1rem !important;
  li {
    position: relative;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin: 0 4px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 8px;
      width: 8px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      background-color: #6e6d6c;
      border-radius: 50%;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: 1;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 10px;
        height: 10px;
        font-size: 4rem;
        line-height: 20px;
        text-align: center;
        color: #000;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
  :global li.slick-active button {
    background-color: #000;
  }
}

.testimonials_list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0 -8px;
  .cardCont {
    flex: 1;
    padding: 0 8px;
    max-width: 50%;
  }
}
.testimonials_grid {
  width: 100%;
  margin: 0 -8px;
  .cardCont {
    padding: 0 8px;
    margin-bottom: 1rem;
  }
}
