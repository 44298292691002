.values {
  padding: 6.25rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 120%;
  width: 48rem;
  text-align: center;
}

.section_desc {
  width: 48rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 150%;
  margin-top: 1.5rem;
  text-align: center;
}

.flex {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.right {
  width: 40rem;
  margin: 0 -12px;
}
.cardCont {
  padding: 0 12px;
  margin-bottom: 2rem;
  .number {
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 1rem;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 1rem;
  }
  .desc {
    line-height: 150%;
  }
}

.left {
  width: 400px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  background-color: #000;
}
.noVideo {
  width: 800px;
  margin-left: 30px;
  background-color: #6e6e6e;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 1024px) {
  .values {
    padding: 3.75rem 1.25rem;
  }

  .section_title {
    font-size: 2.5rem;
    width: 100%;
  }

  .section_desc {
    width: 100%;
    font-size: 1rem;
    margin-top: 1rem;
  }

  .flex {
    flex-direction: column;
    margin-top: 3rem;
  }
  .right,
  .left {
    width: 100%;
  }
  .left {
    height: auto;
  }
}

@media screen and (max-width: 480px) {
}
