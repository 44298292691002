.container {
  padding: 2rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_title {
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1.5rem;
  text-align: center;
}

.description {
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
}

.list {
  width: 100%;
  margin: 0 -8px;
  margin-top: 2rem;
  .cardCont {
    padding: 0 8px;
    margin-bottom: 1rem;
  }
}

.member_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    width: 100%;
    height: 10rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .name {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    width: 100%;
  }

  .function {
    text-align: center;
    font-size: 0.75rem;
    line-height: 120%;
    width: 100%;
  }
}
