.header {
  height: 4rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  padding: 0.813rem 2.25rem 0.813rem 1.063rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}
.appLogo {
  height: 2.375rem;
}
.buttons {
  display: flex;
  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    width: 5.5rem;
    text-align: left;
    cursor: pointer;
  }
  .register {
    text-align: right;
    border-left: 1px solid #d8dde2;
  }
}

.homePage {
  position: relative;
}

.leftSide {
  position: relative;
}

.welcome_to {
  display: block;
  font-weight: 400;
  font-size: 2.5rem;
  height: 2.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.ua_h1 {
  height: 2rem;
  font-weight: 700;
  font-size: 3.125rem;
  margin-bottom: 2.5rem;
  label {
    color: #fff;
  }
}

.home_p {
  color: #fff;
  width: 26.813rem;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
  margin-bottom: 2.375rem;
}
.contact_span {
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.home_btn {
  width: 28.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1rem 0.875rem 1.563rem;
  font-weight: 500;
  font-size: 1.125rem;
  text-transform: uppercase;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 6.125rem 3.813rem 0.875rem 3.813rem;
  .presentation {
    padding-left: 3.813rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 14rem;
    button {
      height: 3.125rem;
      background-color: #fff;
    }
    .firstBtn {
      color: #fff;
      background-color: #29394d;
      margin-bottom: 1.438rem;
    }
  }
  .pending {
    padding-left: 3.813rem;
    margin-bottom: 14rem;
    svg {
      margin-bottom: 1.125rem;
    }
    h1 {
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 2.938rem;
      height: 8.813rem;
      margin-bottom: 1.125rem;
      width: 37.063rem;
    }
    p {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.438rem;
      color: #fff;
      width: 35.313rem;
      margin-bottom: 1.125rem;
    }
  }
  .welcome {
    padding-left: 3.813rem;
    margin-bottom: 14rem;
    button {
      width: 31.5rem;
      color: #fff;
      background-color: #06d9b1;
      margin-bottom: 2.063rem;
    }
  }
  .footer {
    height: 4.5rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #f1f2f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.contentPending {
  padding-top: 4.813rem;
}

.rightSide {
  background-color: #29394d;
  svg {
    margin-left: -16.469rem;
    margin-top: 6.438rem;
  }
}

.video {
  position: absolute;
  top: 11.313rem;
  right: 4.813rem;
  width: 36.875rem;
  height: 23.125rem;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// @media screen and (min-width: 40em) and (max-width: 63.9375em) {
//   .video {
//     top: auto;
//     bottom: 3rem;
//     right: 0;
//     left: 5%;
//     width: 90%;
//   }
// }

@media screen and (max-width: 63.9375em) {
  .leftSide {
    svg {
      width: 100%;
    }
  }
  .ua_h1 {
    height: auto;
    line-height: 3rem;
  }
  .home_p {
    width: auto;
  }
  .home_btn {
    width: 100%;
    height: auto !important;
    display: block;
  }
  .video {
    top: auto;
    bottom: 3rem;
    right: 0;
    left: 5%;
    width: 90%;
  }
  .content {
    width: 100%;

    .presentation {
      padding-left: 1rem;
      margin-bottom: 4rem;
    }
  }

  .rightSide {
    svg {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 39.9375em) {
  .content {
    padding: 6.125rem 1rem 0.875rem;
  }
}
