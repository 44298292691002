.subscription_item {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 4px 4px 20px rgba(194, 194, 194, 0.2);
  border-radius: 20px;
  padding: 3.5rem 7.5rem .5rem;
  width: 45%;
  margin: 4.5rem auto;
  text-align: center;

  .logo {
    display: flex;
    justify-content: center;

    img {
      height: 8rem;
      margin-bottom: 1rem;
    }
  }


  .subscription_block {
    .subscription_header {
      font-size: 30px;
      font-weight: 300;
      color: #29394D;
      margin: 0 0 2rem;
      text-align: center;
    }

    .subscription_content {
      font-family: "Helvetica", "Arial", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #193A61;
    }

    .subscription_actions {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      padding: 2rem 0;
      border-top: 1px solid #F1F2F4;

      .btn_unsubscribe {
        background: #F84054;
        border: none;

        &:hover {
          background: #CC3544;
        }
      }
    }

    .unsubscribe_from_tt {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      cursor: pointer;

      span {
        font-size: 9px;
        color: gray;
        text-decoration: underline;
      }
    }
  }
}