.container {
  background: #ffffff;
  border: 1px solid #06d9b1;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  width: 476px;
  max-width: 100%;
  margin: 200px auto;
  padding: 40px 20px;
  text-align: center;
}

.icon {
  width: 60px;
  height: 60px;
  border: 1px solid #06d9b1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;

  i {
    color: #06d9b1;
    font-size: 20px;
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #06d9b1;
  margin-bottom: 10px;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #29394d;
}

.calendlyBtn {
  margin: 2rem auto 0;
  padding: 1rem 2rem;
  border: 0;
  color: #fff;
  background-color: #18a0fb;
  // border: 1px solid #18a0fb;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: darken(#18a0fb, 5%);
  }
}
