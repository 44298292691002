// modal styling
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.modal_header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  background: #f3faff;
  border-bottom: 1px solid rgba(199, 232, 254, 0.5);
  border-radius: 5px 5px 0 0;
  font-size: 20px;
  line-height: 23px;
  color: #2495e1;

  &_sub {
    font-size: 18px;
    margin-left: 10px;
  }

  i {
    margin-right: 10px;
  }
}
.modal_icon {
  margin-right: 1rem;
}
.modal_body {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 39rem;
  overflow-y: scroll;
  padding: 20px 42px 42px 42px;
  border-bottom: 1px solid #f1f2f4;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.modal_content {
  padding: 2rem;
  min-height: 500px;
  max-height: 60vh;
  overflow: scroll;
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.subtitle {
  color: #6d7f92;
  font-size: 12px;
  line-height: 14px;
}

.left {
  width: 63%;
}

.right {
  width: 35%;
  background: #ffffff;
  border: 1px solid #b2bcc6;
  border-radius: 4px;
  padding: 15px;
  position: relative;
}

.premiumBox {
  margin-top: 15px;

  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(251, 30, 91, 0.1);
    border: 1px solid rgba(251, 30, 91, 0.2);
    font-size: 12px;
    line-height: 16px;

    .user_status {
      color: #fff;
      background-color: #fb1e5b;
      border-radius: 16px;
      padding: 2px 8px;
      font-size: 10px;
      font-weight: bold;
    }
  }
}

.header {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_left {
    display: flex;
    align-items: center;
  }

  .allAction {
    margin-right: 10px;
    cursor: pointer;
    text-decoration: underline;
  }
}

.inputSearch {
  display: flex;
  background: #fff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  border-radius: 30px;
  height: 32px;
  width: 300px;
  align-items: center;

  &_icon {
    color: #b7bdc3;
    cursor: pointer;
    font-size: 1.2rem;
    display: inherit;
    margin: auto 1rem;
  }

  input {
    width: 240px;
    border: none;
    outline: none;
    margin: 0;
    color: #6d7f92;
    font-size: 14px;
    line-height: 16px;
  }
}

.sharedBox {
  background: #ffffff;
  border: 1px solid #fb1e5b;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  margin-top: 15px;
  height: 90%;
  overflow: scroll;
  position: relative;

  &_top {
    padding: 20px;
    color: #fff;
    background: #fb1e5b;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    position: relative;
  }

  &_content {
    padding: 14px;
  }

  &_nodata {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #6d7f92;
  }

  .user {
    width: 100%;
    border: 1px solid #fb1e5b;

    &_status {
      color: #fb1e5b;
    }

    &_actions {
      display: flex;
      align-items: center;
    }

    &_action {
      background: #ffffff;
      border: 1px solid #d8dde2;
      // color: #fb1e5b;
      font-size: 10px;

      &:hover {
        background: #f1f2f4;
      }
    }

    &_action_delete {
      margin-left: 5px;
      background: #ffffff;
      border: 1px solid rgba(254, 55, 69, 0.3);
      color: #fb1e5b;
      font-size: 10px;

      &:hover {
        background: rgba(254, 55, 69, 0.3);
      }
    }
  }
}

.info {
  cursor: pointer;
  margin-left: 10px;
  display: inline;
  position: absolute;
  right: 30px;
  top: 70px;
  z-index: 1;

  &:hover &_box {
    display: block;
  }

  &_box {
    display: none;
    background: #ffffff;
    border: 1px solid #f1f2f4;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    z-index: 999;
    position: absolute;
    top: 50%;
    right: 20px;
    width: 250px;
    padding: 15px;
    font-size: 12px;
    line-height: 14px;
    transform: translateY(-50%);
  }

  h3 {
    color: #fb1e5b;
  }

  ul {
    margin: 10px 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    span {
      margin-left: 5px;
    }
  }

  p {
    color: #6d7f92;
  }

  span {
    white-space: nowrap;
  }
  i {
    font-size: 14px;

    &:before {
      color: #fff;
    }
  }
}

.users {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user {
  width: 49%;
  background: #ffffff;
  border: 1px solid #d8dde2;
  border-radius: 4px;
  padding: 10px;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_name {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 16px;
  }

  &_status {
    font-weight: 500;
    color: #6d7f92;
  }

  &_actions {
    display: flex;
    align-items: center;
  }

  &_action {
    background: #ffffff;
    border: 1px solid #d8dde2;
    border-radius: 5px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6d7f92;
    cursor: pointer;

    &:hover {
      background: #f1f2f4;
    }
  }

  &_action_send {
    margin-right: 5px;
    background: #ffffff;
    border: 1px solid #18a0fb;
    color: #18a0fb;
    font-size: 10px;

    &:hover {
      background: #f3faff;
    }
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-width: 2px;
  border-color: #fff;
  border-top-color: #18a0fb;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.backNav {
  background-color: #fafbfb;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    height: 2.25rem;
    padding: 0px 1.188rem;
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-right: 1.188rem;

    svg {
      margin-right: 0.688rem;
    }
  }

  &_title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #18a0fb;

    span {
      color: #000;
    }
  }
}

.show_more {
  margin: 20px auto 40px;
  cursor: pointer;
  padding: 12px 22px;
  background: #ffffff;
  border: 1px solid #b2bcc6;
  border-radius: 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #29394d;

  &:hover {
    background-color: #ececec;
  }
}
