.headerTab {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 2rem !important;

  &__button {
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 206px;
    height: 54px;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #18a0fb;
    outline: none;
    text-transform: uppercase;
    transition: box-shadow 0.2s, transform 0.2s;
    border: none;
    &:hover {
      box-shadow: 2px 2px 11px rgba(41, 57, 77, 0.05);
      transform: scale(0.99);
    }

    &_active {
      background: linear-gradient(180deg, #2495e1 0%, #18a0fb 100%);
      border: 1px solid #eef6ff;
      color: #fff;
    }
  }
}
