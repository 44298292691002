.container {
  padding: 2rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_title {
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1rem;
  text-align: center;
}

.description {
  width: 90%;
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
}

.list {
  display: flex;
  align-items: flex-start;
  margin: 0 -8px;
  margin-top: 2rem;
  .cardCont {
    padding: 0 8px;
  }
}

.list_grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -8px;
  margin-top: 2rem;
  .cardCont {
    padding: 0 8px;
  }
}

.articleCard {
  color: #000 !important;
  max-width: 232px;
  border: 1px solid #000;
  .img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 10rem;
  }
  .content {
    padding: 0.5rem;
    .category {
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 0.25rem;
    }
    .title {
      font-size: 1rem;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 0.5rem;
      width: 100%;
      text-align: left;
    }
    .description {
      font-size: 12px;
      line-height: 150%;
      margin-bottom: 0.75rem;
      width: 100%;
      text-align: left;
    }
    .author {
      display: flex;
      align-items: center;
      .img {
        height: 2rem;
        width: 2rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 100%;
        margin-right: 0.5rem;
      }
      .info {
        .name {
          font-size: 12px;
          font-weight: 600;
          line-height: 150%;
        }
        .published {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 150%;
          .dot {
            height: 8px;
            width: 8px;
            background-color: #000;
            margin: 0 0.5rem;
            border-radius: 100%;
          }
        }
      }
    }
  }
}
