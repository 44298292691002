.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #29394d;
  margin: 42px 0 18px;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #29394d;
  text-align: center;
  margin: 45px 0 15px;
  min-height: 19px;
}
.subTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #29394d;
  margin-bottom: 30px;
}
.body {
  display: block;
  padding: 75px; //44px;
  scroll-margin-bottom: 200px;
  background: #fff;
  border: 1px solid #18a0fb;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 15px;
    text-align: justify;
  }
}

.apps {
  margin: 20px 40px 25px 10px !important;
  li {
    display: flex;
    align-items: center;
    padding-left: 22px;
    position: relative;
    height: 34px;
    border-top: 1px solid #f1f2f4;
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: #f7953e;
    }
    &:first-child {
      border: none;
    }
    img {
      margin-right: 10px;
    }
    i {
      margin-left: auto;
      cursor: pointer;
      font-size: 18px;
      &:hover::before {
        color: #18a0fb;
      }
    }
  }
  &.on {
    margin-top: 0 !important;
    li::before {
      background: #06d9b1;
    }
  }
}

.bodyTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #18a0fb;
  margin-bottom: 6px;
}
.bodySubTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #6d7f92;
  margin-bottom: 30px;
}

.fixedBar {
  height: 100px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  position: fixed;
  bottom: 0;
  border-top: 1px solid #d8dde2;
  > div {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.endSpace {
  padding-bottom: 110px;
}

.actionCheck {
  margin: 0 40px 0 auto;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
    margin-right: 10px;
  }

  span {
    color: #18a0fb;
    cursor: pointer;
  }
}
.accreditation {
  background: #f8f9fa;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  height: 34px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    margin-right: 20px;
    padding: 0 40px 0 20px;
    border-right: 1px solid #b2bcc6;
  }
  input {
    flex: 2;
    border: none;
    background: transparent;
    font-size: 14px;
  }
}
.separator {
  border: 1px solid #f1f2f4;
  width: 100%;
  margin: 24px 0;
}
.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #29394d;
  margin-bottom: 14px;
  display: block;
  text-align: left;
  span {
    color: #18a0fb;
  }
}

.final {
  border: 1px solid #18a0fb;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;

  &_title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    padding: 16px 28px;
    color: #18a0fb;
    background-color: #f3faff;
    border-radius: 5px 5px 0 0;
  }

  &_body {
    padding: 0 28px;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    display: flex;
  }

  .benefits {
    padding-top: 28px;
    &.portals {
      width: 30%;
      border-right: 1px solid #d8dde2;

      .benefits_text {
        margin-right: 49px;
      }
    }

    &_apps {
      width: 70%;
      margin-left: 28px;
    }

    &_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #18a0fb;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      padding-bottom: 15px;
      margin-bottom: 27px;
      border-bottom: 1px solid #d8dde2;
    }

    &_icon {
      border-radius: 50%;
      color: #fff;
      background-color: #18a0fb;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      margin-right: 10px;
    }

    &_text {
      font-size: 14px;
      line-height: 16px;
      color: #29394d;
      padding-bottom: 15px;
      margin-bottom: 27px;

      p {
        margin-top: 1rem;
      }
    }

    .first_portal {
      border-bottom: 1px solid #d8dde2;
    }
  }
}

.apps_content {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 20px;

  .apps_box {
    background: #f3faff;
    border-radius: 5px;
    color: #18a0fb;
    border-radius: 5px;
    margin: 20px 0;
    padding: 20px 16px 20px 30px;

    ul {
      list-style-type: disc;
    }

    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-bottom: 10px;
    }
  }

  .sep {
    background: #f1f2f4;
    width: 2px;
    margin: 0 30px;
  }
}

.appName {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 1rem;

  img {
    margin-right: 10px;
  }
}

.app_watch {
  display: flex;
  flex-direction: column;
  line-height: 14px;

  .tag {
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
    text-transform: capitalize;
    color: #fff;
    background: #e02d5c;
    border-radius: 3px;
    padding: 2px 4px;
  }
}

.sizeBar {
  width: 100%;
  // margin: 0 auto 20px;
  position: relative;

  ul {
    padding-bottom: 12px;
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  li {
    width: 158px;
    height: 114px;
    margin-right: 20px;
    box-sizing: border-box;
    transition: 0.5s ease-in-out;
    flex-shrink: 0;
  }
}
.scrollButton {
  z-index: 2;
  position: absolute;
  top: 35px;
  height: 2rem;
  width: 9.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transform: rotate(90deg);
  }
}
.scrollButtonForward {
  right: -40px;
}
.scrollButtonBack {
  justify-content: flex-start;
  left: -40px;

  button {
    transform: rotate(270deg);
  }
}
.errorCol {
  color: #d50000;
  font-size: 12px;
  display: block;
  line-height: 16px;
  margin-bottom: 5px;
}
