.container {
  background-color: #f4f7f9;
  // margin-bottom: 18px;

  ul {
    display: flex;
    align-items: center;
    height: 60px;
    overflow-y: scroll;
  }

  li {
    display: flex;
    align-items: center;
    margin-right: 2.5rem;
    color: #b6bfc8;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    p {
      color: #b6bfc8;
    }
    &.active {
      color: #18a0fb;
      p {
        color: #18a0fb;
      }
      .icon {
        background-color: #18a0fb;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #b2bcc6;
      color: #fff;
      font-weight: 500;
      margin-right: 1rem;
      font-size: 16px;
      line-height: 19px;

      i {
        font-size: 12px;
      }
    }
  }
}
