.container {
  padding: 2rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.list {
  display: flex;
  align-items: center;
  .item {
    width: 5rem;
    height: 3rem;
    background-color: #dedede;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
