.title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 5px;
}
.subTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  margin-bottom: 40px;
}
.separator {
  border: 1px solid #f1f2f4;
  width: 100%;
  margin: 40px 0 15px;
}

.actionBar {
  border-top: 1px solid #f1f2f4;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);

  > div {
    display: flex;
    justify-content: space-between;
  }

  input {
    margin-right: 10px;
  }
  label {
    margin-right: 45px;
  }
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px dashed #b2bcc6;
  box-sizing: border-box;
  border-radius: 5px;
  width: 220px;
  height: 125px;
  cursor: pointer;
}
.checkbox span {
  color: #18a0fb;
  cursor: pointer;
}
