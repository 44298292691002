.packs_section {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_title {
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
}

.title {
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1.5rem;
  text-align: center;
}

.description {
  width: 48rem;
  font-size: 1.125rem;
  line-height: 150%;
  text-align: center;
}

.list {
  width: 100%;
  margin-top: 3rem;
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(3, 1fr);
  .cardCont {
    // padding: 0 16px;
  }
}

.pack_card {
  padding: 1rem;
  border: 1px solid #000;
  .title {
    text-align: left;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 4px;
  }
  .subtitle {
    padding-bottom: 2rem;
    border-bottom: 1px solid #000;
    margin-bottom: 2rem;
  }
  .monthly {
    display: flex;
    align-items: flex-end;
    font-size: 2rem;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 0.5rem;
    strong {
      font-size: 3.5rem;
      line-height: 100%;
    }
  }
  .btn {
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 3rem;
    margin: 2rem 0;
  }
  .features {
    padding-top: 2rem;
    border-top: 1px solid #000;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      svg {
        margin: 0;
        margin-right: 1rem;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .packs_section {
    padding: 3.75rem 1.25rem;
  }
  .title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .description {
    font-size: 1rem;
    width: 100%;
  }
  .list {
    margin-top: 3.75rem;
    grid-template-columns: repeat(2, 1fr);
    .cardCont {
      margin-bottom: 2rem;
    }
  }
}
@media screen and (max-width: 480px) {
  .list {
    grid-template-columns: repeat(1, 1fr);
    .cardCont {
      padding: 0;
    }
  }
}
.noMargin {
  margin-top: 0;
}
