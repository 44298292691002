.content {
  max-width: 700px;
  margin: 2rem auto 0;
}
.top {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  color: #6d7f92;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;
    margin-bottom: 10px;
  }

  &_right {
    padding: 18px;
    border: 1px solid #f1f2f4;
    border-radius: 5px;
    width: 40%;
    margin-left: 50px;
  }
}

.savings {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 22px;
  color: #18a0fb;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 8px;
  }
}
.period {
  display: flex;
  justify-content: space-between;

  &_item {
    width: 48%;

    &_box {
      cursor: pointer;
      position: relative;
      padding: 14px 21px;
      background: #ffffff;
      border: 1px solid #b2bcc6;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      margin-bottom: 10px;

      &.checked {
        background: #f3faff;
        border: 1px solid #18a0fb;
      }
    }

    &_check {
      position: absolute;
      right: 10px;
      top: 14px;
      width: 18px;
      height: 18px;
      background: #f8f9fa;
      border: 2px solid #b2bcc6;
      border-radius: 18px;
    }

    &_checkicon {
      position: absolute;
      right: 10px;
      top: 14px;
      width: 18px;
      height: 18px;
      background-color: #18a0fb;
      color: #fff;
      border-radius: 50%;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #18a0fb;
    margin-bottom: 20px;
  }

  &_price {
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 5px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &_txt {
    font-size: 14px;
    line-height: 22px;
    color: #6d7f92;
  }

  &_collab {
    font-size: 14px;
    line-height: 22px;
    color: #6d7f92;
  }
}

.period_center {
  justify-content: center;
}

.freePeriod {
  margin: 20px 0;
  background: #f3faff;
  border-radius: 5px;
  padding: 28px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;

  &_title {
    font-size: 16px;
    line-height: 19px;
    color: #18a0fb;
    margin-bottom: 15px !important;
  }

  p {
    margin-bottom: 5px;
  }
}
