.iframe {
  width: 100%;
  height: 70vh;
  z-index: 0;
  box-shadow: none;
  border: none;
  background-color: #fcfcfc;
}

.payment {
  padding: 1.125rem 7.125rem 0rem 7.125rem;
  background-color: #fff;
  min-height: 70vh;

  // span {
  //   display: block;
  //   font-size: 0.75rem;
  //   font-weight: 400;
  //   color: #6d7f92;
  // }
  // h4 {
  //   font-weight: 500;
  //   font-size: 1.125rem;
  // }
}

.title {
  h4 {
    margin-bottom: 0.313rem;
  }
  span {
    font-size: 0.875rem;
    margin-bottom: 2.313rem;
  }
}
.content {
  padding: 0rem 1.5rem 0rem 1.5rem;
  span {
    line-height: 0.875rem;
  }
}
.command_details {
  border-right: 1px solid #d8dde2;
  padding: 2.063rem 3.375rem 8.5rem 0rem;
}
.titles {
  h4 {
    margin-bottom: 0.563rem;
  }
}
.vcs {
  font-size: 12px;
  line-height: 14px;
  width: 11.25rem;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 0.75rem 1.725rem 0.584rem 1.125rem;

  span {
    display: block;
  }
}

.header {
  margin-bottom: 2.438rem;
}

.details {
  margin-bottom: 1.5rem;
  table {
    width: 100%;
    thead {
      border-bottom: 1px solid #f1f2f4;
      th {
        font-weight: 500;
        font-size: 0.75rem;
        text-align: center;
        padding-bottom: 0.625rem;
      }
    }
    tbody tr {
      border-bottom: 1px solid #f1f2f4;
      td {
        padding: 0.625rem 0rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.75rem;
      }
    }
    .desc {
      text-align: left;
    }
  }
}

.total {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.download {
  height: 1.75rem;
  display: flex;
  align-items: center;
  padding: 0rem 1.125rem 0rem 0.938rem;
  border: 1px solid #d8dde2;
  border-radius: 15px;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  color: #6d7f92;

  span {
    margin-left: 8px;
  }
}
.total_details {
  width: 16.813rem;
  height: 6.063rem;
  background: #f8f9fa;
  border-radius: 5px;
  padding: 0.688rem 0.938rem 0.688rem 0.938rem;
  table {
    width: 100%;
    tbody tr {
      border-bottom: 1px solid #f1f2f4;
      td {
        padding: 0.5rem 0rem 0.5rem 1.25rem;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }
      .val {
        padding-left: 2.5rem;
      }
    }
    .first td {
      padding-top: 0rem;
    }
    .last {
      border-bottom: none;
      td {
        padding-bottom: 0rem;
        color: #18a0fb;
      }
    }
  }
}

.price {
  padding: 2.063rem 0rem 3.5rem 3.125rem;
}
.price_titles {
  margin-bottom: 1rem;
  h4 {
    margin-bottom: 0.563rem;
    label {
      color: #18a0fb;
    }
  }
}

.option {
  width: 22.25rem;
  border: 1px solid #c7e8fe;
  border-radius: 5px;
  margin-bottom: 0.875rem;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    font-weight: 500;
    font-size: 0.875rem;
  }
}

.qrCode {
  padding: 0.813rem 1.188rem;
  .img {
    width: 6rem;
    height: 5.875rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  div p {
    margin-top: 0.625rem;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    label {
      text-decoration: underline;
    }
  }
}
.online {
  padding: 0.938rem 1.375rem 1.188rem 1.188rem;
  cursor: pointer;
  div .imgs {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    .img {
      width: 1.875rem;
      height: 1.188rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
.choose {
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    cursor: pointer;
  }
  .checkbox {
    height: 1.375rem;
    width: 1.375rem;
    background: #f1f2f4;
    border: 2px solid #b2bcc6;
    border-radius: 100%;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}

.choose_active {
  span {
    color: #18a0fb;
  }
  .checkbox {
    background: #18a0fb;
    border: 2px solid #fff;
  }
}

.option_active {
  border: 1px solid #18a0fb;
  background-color: #f3faff;
}

.step2Content {
  display: flex;
}

.backBtn {
  width: 4.188rem;
  height: 29.313rem;
  border-right: 1px solid #d8dde2;
  padding: 0.5rem 1.313rem 0rem 0rem;
  button {
    width: 2.75rem;
    height: 2.75rem;
    background: #ffffff;
    border: 1px solid #b2bcc6;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
  }
}
.step2price {
  width: fit-content;
  padding-right: 2.688rem;
}
.cards {
  border: 1px solid #18a0fb;
  border-radius: 5px;
  padding: 1.313rem 1.813rem 1.75rem 1.813rem;
  h5 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
  ul {
    li {
      width: 39.75rem;
      height: 3.5rem;
      background: #f8f9fa;
      border: 1px solid #d6dbe0;
      border-radius: 4px;
      margin-bottom: 0.75rem;
      padding: 0rem 0.5rem 0rem 1.125rem;
      display: flex;
      align-items: center;
      .img {
        height: 2.25rem;
        width: 2.25rem;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 100%;
        margin-right: 1.75rem;
      }
      p {
        font-weight: 500;
        font-size: 0.875rem;
        margin-right: 3.25rem;
      }
      svg {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }

  .new_card {
    margin-top: 1.375rem;
    background: #f8f9fa;
    border: 1px solid #d6dbe0;
    border-radius: 4px;
    padding: 1.125rem 1.625rem 1.688rem 1.625rem;
    display: flex;
    flex-direction: column;
    h5 {
      font-weight: 500;
      font-size: 0.875rem;
      color: #18a0fb;
      margin-bottom: 1.25rem;
    }

    .secondRow {
      margin-top: 0.875rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
    }

    .choose {
      margin-left: auto;
      margin-bottom: 1.313rem;
      .checkbox {
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
      }
      label {
        font-weight: 400;
        font-size: 0.75rem;
        color: #3c4e64;
        cursor: pointer;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cancel {
        width: 5.625rem;
        height: 2.125rem;
        background: #f1f2f4;
        font-weight: 500;
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #6d7f92;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
.payBtn {
  width: 10.75rem;
  height: 2.125rem;
  background-color: #18a0fb;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
}
.disabledBtn {
  opacity: 0.5;
  cursor: not-allowed;
}

.step3Content {
  padding: 3.313rem 0rem 6.563rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.refusedPayment {
  width: 29.75rem;
  border: 1px solid #fe3745;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  padding: 2.438rem 0rem 3.125rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    font-weight: 500;
    font-size: 1.125rem;
    color: #fe3745;
    margin-top: 1.438rem;
    margin-bottom: 1rem;
  }
  p {
    width: 21.188rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    margin-bottom: 2.5rem;
  }
  button {
    width: 14rem;
    height: 2.375rem;
    text-transform: uppercase;
  }
}

.step4Content {
  padding: 3rem 1.5rem 0rem 1.5rem;
  .recap {
    padding: 2.063rem 0rem 0rem 3.313rem;
    h4 {
      margin-bottom: 2rem;
    }
    .section {
      border-top: 1px solid #e8ebee;
      margin-bottom: 1.875rem;
      .sectionTitle {
        width: fit-content;
        background-color: #fff;
        color: #18a0fb;
        font-weight: 500;
        font-size: 0.75rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        padding-right: 0.75rem;
        margin-top: -0.75rem;
        i {
          margin-right: 0.5rem;
        }
      }
      h6 {
        margin-top: 0.75rem;
        margin-bottom: 0.375rem;
        font-weight: 500;
        font-size: 0.875rem;
      }
      p {
        font-weight: 400;
        font-size: 0.75rem;
        .bold {
          font-weight: 500;
        }
      }
    }
    .congrats {
      color: #02af8e;
      font-weight: 500;
      font-size: 0.875rem;
      text-align: center;

      i {
        font-size: 0.75rem;
        margin-right: 0.75rem;
      }
    }
  }
}

.successSub {
  color: #02af8e;
}

.freeCycle {
  margin-top: 3rem;

  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 2rem;
  }
  .section {
    border-top: 1px solid #e8ebee;
    margin-bottom: 1.875rem;
    .sectionTitle {
      width: fit-content;
      background-color: #fff;
      color: #18a0fb;
      font-weight: 500;
      font-size: 0.75rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      padding-right: 0.75rem;
      margin-top: -0.75rem;
      i {
        margin-right: 0.5rem;
      }
    }
    h6 {
      margin-top: 0.75rem;
      margin-bottom: 0.375rem;
      font-weight: 500;
      font-size: 0.875rem;
    }
    p {
      font-weight: 400;
      font-size: 0.75rem;
      .bold {
        font-weight: 500;
      }
    }
  }
}

.actionBar {
  border-top: 1px solid #f1f2f4;
  margin: 40px;
  padding-top: 20px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
}
