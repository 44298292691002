.media_slider {
  position: relative;
  height: 20rem;
  .slick-slider {
    height: 20rem;
  }
}

.media_slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 20rem;
}

.title {
  text-align: center;
  width: 30rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 0.5rem;
}

.description {
  text-align: center;
  width: 30rem;
  font-size: 14px;
  line-height: 150%;
}

.slider_btn {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  border: 1px solid #000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
}

.right_btn {
  left: unset;
  right: 2rem;
}

.member_badge {
  height: 54px;
  position: absolute;
  bottom: 24px;
  right: 25px;
  background-color: #fff;
  border-radius: 8px;
  padding-left: 14px;
  padding-right: 4px;
  display: flex;
  align-items: center;
  .left {
    width: 173px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-align: right;
    margin-right: 4px;
    .blue {
      color: #31619f;
      text-transform: uppercase;
    }
  }
}
.slick_dots {
  transform: scale(0);
  margin: 0;
  height: 0;
}
