.container {
  display: flex;
}
.leftSide {
  background-color: #18a0fb;
  color: #ffffff;
  flex-basis: 450px;
  flex-shrink: 0;
  height: 100vh;
  padding: 2rem 4rem;
  font-size: 14px;
  line-height: 16px;
  position: relative;

  &_headTitle {
    margin: 2rem 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }
}

.rightSide {
  width: 100%;
  padding: 2rem;
  background-color: #ffffff;
}

.topButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  line-height: 16px;

  .register {
    font-weight: 500;
    background: #f1f2f4;
    box-shadow: 0px 5px 5px rgba(238, 239, 239, 0.15);
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1rem;
    padding: 14px 18px;

    &:hover {
      background: #e1e4e8;
    }
  }
}

.content {
  width: 50%;
  margin: 2rem auto;
}

.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;
  margin-bottom: 2rem;
  color: #29394d;
}

.resetTitle {
  font-weight: 700;
  font-size: 38px;
  line-height: 32px;
  margin-bottom: 2rem;
}

.flexLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  padding: 9px 15px;
}

.forgotLabel {
  color: #6d7f92;
  cursor: pointer;
  font-weight: 500;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 2rem;
}

.signinLink {
  cursor: pointer;
}

.orSep {
  text-align: center;
  line-height: 0.1em;
  position: relative;
  margin: 10px 0 25px;
  background: #fff;

  span {
    background: #fff;
    padding: 0 1.5rem;
    color: #29394d;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    height: 1px;
    width: 100%;
    background-color: #d8dde2;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
}

.socialContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialBtn {
  width: 66px;
  height: 66px;
  background: #ffffff;
  border: 1px solid #c7e8fe;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;

  img {
    width: 34px;
    height: 34px;
  }
}

.footer {
  border-top: 1px solid #d8dde2;
  margin-top: 3rem;
  padding-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.pwdInput {
  &.error {
    border: 1px solid #d50000;
  }
}
span.error {
  color: #d50000;
  font-size: 12px;
  display: block;
  text-align: right;
  line-height: 16px;
  margin-top: 4px;
}
.progress {
  position: absolute;
  bottom: 10px;
  left: 0;
  margin: 0;
  width: 45%;
  height: 3px;
  border-radius: 10px;
  background: #d8dde2;

  &.error {
    color: #fe3745;
  }
}

.passwordBox {
  position: relative;
}

.emailInputBox {
  position: relative;

  i {
    position: absolute;
    right: 10px;
    bottom: 33px;
    color: #06d9b1;

    &:before {
      color: #06d9b1;
    }
  }
}

.successMsg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  color: #06d9b1;
  text-align: center;

  i {
    font-size: 80px;
    margin-bottom: 2rem;

    &::before {
      color: #06d9b1;
    }
  }

  span {
    font-size: 32px;
    line-height: 36px;
  }

  &_send {
    margin-top: 1rem;
    font-size: 22px !important;
    line-height: 26px !important;
    cursor: pointer;
  }
}

@media screen and (max-width: 39.9375em) {
  .content {
    width: 100%;
  }

  .leftSide {
    padding: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column-reverse;
  }
}
