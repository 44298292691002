.header {
    margin: 40px;
    .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 140%;
        text-align: center;
        color: #29394D;
        margin-bottom: 25px;
        span {
            color:#18A0FB;
        }
    }
    .searchBar {
        margin: 0 50px 20px;
    }
}
.no-data {
    text-align: center;
    font-size: 24px;
    margin: 40px 0;
}
