.container {
  padding: 2rem 1.25rem;
  display: flex;
}

.left {
  padding-top: 2rem;
  margin-left: 2rem;
  color: #000;
  width: 20rem;
}

.about_title {
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1rem;
}

.description {
  font-size: 1rem;
  line-height: 150%;
}

.img {
  flex-grow: 1;
  height: 20rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
