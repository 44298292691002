.tops {
    background: #F1F2F4;
    border-radius: 5px;
    min-height: 1086px;
    padding: 20px;
    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: #29394D;
        margin-bottom: 26px;
        span {
            color: #18A0FB;
            display: block;
        }
    }
    
}

.media {
    width: 100%;
    height: 140px;
    border-radius: 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.article {
    padding: 20px 0;
    border-bottom: 1px solid rgba(216, 221, 226, 0.51);
    position: relative;
    min-height: 100px;
    &:last-child {
        border:none;
    }
    .num {
        font-weight: 600;
        font-size: 80px;
        line-height: 94px;
        color: #D8DDE2;
        position: absolute;
        top: 0;
        right: 0;
    }
    h3 a{
        position: relative;
        z-index: 1;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #29394D;
    }
}
.meta {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-bottom: 7px;
    span {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #6D7F92;
    }
    .meta-category {
        padding: 0 0.25rem;
        margin-right: 5px;
        padding-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .meta-community {
        position: relative;
        z-index: 1;
        height: 20px;
        border-left: 3px solid black;
        padding-left: 6px;
        line-height: 20px;
    }
}

