.subtitle {
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  margin-bottom: 1.25rem;
}
.actionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 10px rgb(41 57 77 / 10%);

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.dragHandler {
  font-size: 2rem;
  color: #b6bfc8;
  cursor: move;
}
