.course {
    height: 490px;
    background: #F1F2F4;
    border-radius: 5px;
    padding: 12px 20px;
    h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: #29394D;
        margin-bottom: 10px;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #6D7F92;
        margin-bottom: 40px;
    }
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #18A0FB;
   
}
.all {
    filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    padding: 12px 26px;
    text-align: center;
    text-transform: uppercase;
    color: #29394D;
    background: #FFFFFF;
    border: 1px solid #D8DDE2;
    box-sizing: border-box;
    border-radius: 5px;
}
.titleNext {
    padding: 40px 0 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #29394D;
    filter: drop-shadow(0px 2px 10px rgba(41, 57, 77, 0.1));
}
.event {
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    box-sizing: border-box;
    border-radius: 5px;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    .picture {
        background: #6D7F92;
        border-radius: 5px; 
        height: 140px;
        position: relative;
        ul {
            position: absolute;
            bottom: -40
            px;
            left: 10px;
            display: flex;
            
            li {
                margin-right:6px; 
                padding: 5px 10px;
                background: #FFFFFF;
                border-radius: 5px;

                font-weight: 500;
                font-size: 12px;
                line-height: 140%;
                text-transform: lowercase;
                color: #29394D;
            }
        }
    }
    .body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex:1;
    }
    h3 {
        margin: 26px 0 auto;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #29394D;
    }
    .author {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #6D7F92;
    }
    ul {
        margin: 12px  0 20px;
        li {
            display: flex;
            font-weight: 400;
            font-size: 12px;
            line-height: 13px;
            color: #29394D;
            margin-bottom: 10px;
            i {
                margin-right: 10px;
            }
        }
    }
    button {
        border: none;
        background: #06D9B1;
        border-radius: 5px;
        width: 100%;

        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 10px 0; 
    }
}
