.card {
  background: #ffffff;
  border: 1px solid #b2bcc6;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  position: relative;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  &.disabled {
    cursor: not-allowed;
  }
  &.selected {
    background: #f3faff;
    border: 1px solid #18a0fb;
    .checkbox {
      background: #18a0fb;
      border: none;
      font-size: 10px;
      i:before {
        color: #fff;
      }
    }
  }
  &.large {
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
  }
  label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6d7f92;
    margin-top: 26px;
    span {
      color: #18a0fb;
      font-weight: 500;
    }
  }
}

.checkbox {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 16px;
  top: 16px;

  background: #f8f9fa;
  border: 2px solid #b2bcc6;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  margin-top: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #6d7f92;
  min-height: 19px;
}
.subTitle {
  margin-top: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #18a0fb;
}
.desc {
  margin-top: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #6d7f92;
}
.icon {
  margin-top: 14px;
}
