// modal styling
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.modal_header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  background: #f3faff;
  border-bottom: 1px solid rgba(199, 232, 254, 0.5);
  border-radius: 5px 5px 0 0;
  font-size: 20px;
  line-height: 23px;
  color: #2495e1;

  &_sub {
    font-size: 18px;
    margin-left: 10px;
  }

  i {
    margin-right: 10px;
  }
}
.modal_icon {
  margin-right: 1rem;
}
.modal_body {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 39rem;
  overflow-y: scroll;
  padding: 20px 42px 42px 42px;
  border-bottom: 1px solid #f1f2f4;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.modal_content {
  padding: 2rem;
  min-height: 500px;
  max-height: 60vh;
  overflow: scroll;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
}

.top {
  font-size: 12px;
  line-height: 14px;
  color: #6d7f92;
  width: 100%;
  margin-bottom: 10px;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;
    margin-bottom: 5px;
  }
}

.pack {
  color: #18a0fb;
  margin-bottom: 10px;
  text-align: center;
}

.box {
  background: #ffffff;
  border: 1px solid #b2bcc6;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  width: 70%;
  margin: 0 auto;

  &.checked {
    background: #f3faff;
    border: 1px solid #18a0fb;
  }
}

.plan {
  padding: 14px 21px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8dde2;

  &_name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    span {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #6d7f92;
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100px;
  }

  &_price {
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
  }

  &_info {
    font-size: 14px;
    line-height: 22px;
    color: #6d7f92;
  }
}

.box_users {
  padding: 14px 21px;
  display: flex;
  justify-content: space-between;

  &_name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    span {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #6d7f92;
    }
  }
}

.countBox {
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    font-weight: 500;
    font-size: 16px;
    user-select: none;
  }

  .minus {
    background: #f3faff;
    border: 1px solid #d8dde2;
    border-radius: 4px 0 0 4px;
    width: 40px;
    cursor: pointer;
    color: #18a0fb;
  }

  .value {
    width: 60px;
    background: #fff;
    border-top: 1px solid #d8dde2;
    border-bottom: 1px solid #d8dde2;
  }

  .plus {
    background: #f3faff;
    border: 1px solid #d8dde2;
    border-radius: 0 4px 4px 0;
    width: 40px;
    cursor: pointer;
    color: #18a0fb;
  }
}

.summary {
  margin: 20px auto;

  &_title {
    font-size: 16px;
    line-height: 19px;
    color: #18a0fb;
    margin-bottom: 15px !important;
  }

  p {
    margin-bottom: 5px;
  }
}

.loadBox {
  width: 70%;
  margin: 2rem auto 0;
}

.newbox {
  position: relative;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-width: 2px;
  border-color: #fff;
  border-top-color: #18a0fb;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
