.content {
  width: 60%;
  margin: 4rem auto 8rem;
  padding: 20px;
  // min-height: 70vh;
  background: #fff;
  box-shadow: 0px 5px 5px rgba(238, 239, 239, 0.15);
  border-radius: 5px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1rem;
  color: #29394d;
}

.codeInput {
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none !important;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input {
    margin-left: 5px;
  }
}

.codeBox  {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.actions {
  margin-top: 2rem;
}
