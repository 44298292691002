// modal styling
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.modal_header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  background: #f3faff;
  border-bottom: 1px solid rgba(199, 232, 254, 0.5);
  border-radius: 5px 5px 0 0;
  font-size: 20px;
  line-height: 23px;
  color: #2495e1;

  &_sub {
    font-size: 18px;
    margin-left: 10px;
  }

  i {
    margin-right: 10px;
  }
}
.modal_icon {
  margin-right: 1rem;
}
.modal_body {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 39rem;
  overflow-y: scroll;
  padding: 20px 42px 42px 42px;
  border-bottom: 1px solid #f1f2f4;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.modal_content {
  padding: 2rem;
  min-height: 500px;
  max-height: 60vh;
  overflow: scroll;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
}

.actions2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
}

.appsContainer {
  display: flex;
}

.leftApps {
  width: 253px;
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid #f1f2f4;
}

.rightApps {
  flex: 1;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
}

.subtitle {
  color: #6d7f92;
  font-size: 12px;
  line-height: 14px;
}

.modal_apps {
  display: flex;
  flex-direction: column;
  margin-top: 1rem !important;

  li {
    padding: 0.75rem 1rem;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #d8dde2;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    position: relative;

    &.active,
    &:hover {
      color: #c7e8fe;
      background: #29394d;
    }

    &.active {
      &:after {
        font-family: "tamtam";
        content: "\e91d";
        position: absolute;
        right: 20px;
        top: 22px;
        z-index: 10;
        transform: rotate(90deg);
      }
    }

    &.disabled {
      color: #29394d;
      background: #ffffff;
      cursor: not-allowed;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    img {
      margin-right: 10px;
    }
  }
}
.userRow {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 2px;
  background: #fafbfb;
  border-radius: 2px;

  &_green {
    background: #edfaf5;
  }

  &_header {
    background-color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    .emptyAvatar {
      width: 34px;
    }
  }

  &_infos {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }
}

.watchRow {
  .userRow_infos {
    width: 50%;
  }

  .subscribeSelect {
    width: 25% !important;
  }
}

.alignCenter {
  text-align: center;
}

.roleSelect {
  width: 180px;
}

.lngSelect {
  width: 150px;
  margin: 0 1rem;
}

.countArt {
  width: 48px;
  text-align: center;
}

.subscribeSelect {
  width: 130px;
  margin: 0 1rem;
}
.subscriber {
  background: #edfaf5;
  border: 1px solid #dcf5ec;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  color: #06d9b1;
  padding: 8px;
  text-align: center;
}
.subscribeCheckbox {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 5px;
    background: #f1f2f4;
    border: 2px solid #b2bcc6;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    border-color: #06d9b1;
    background-color: #06d9b1;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 4px;
    top: 0px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.certificate {
  width: 100px;

  &_yes,
  &_no {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;

    i {
      font-size: 14px;
      margin-right: 5px;

      &:before {
        color: #06d9b1;
      }
    }
  }

  &_no {
    i {
      &:before {
        color: #fe3745;
      }
    }
  }
}

.blog_box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 1rem 0 !important;

  &_item {
    flex: 1;
    border: 1px solid #d8dde2;
    border-radius: 8px;
    padding: 1.25rem;
    height: 200px;

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}
