@import "/src/styles/base/variables";

body {
  background-color: $body-bg !important;
}
.mobileBanner {
  display: none;
}
.page {
  padding-top: 2rem;
}

.leftBanner {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  min-height: 400px;
  padding: 2rem 0rem 2.5rem;
  margin-bottom: 1rem;

  &_img {
    width: 210px;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d8dde2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    margin: 0 auto 2rem;
    position: relative;

    &:hover {
      .edit {
        display: initial;
      }
    }
    .edit {
      position: absolute;
      top: 5%;
      right: 5%;
      cursor: pointer;
      display: none;
    }

    .img {
      width: 185px;
      height: 90px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &_name {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #29394d;
    text-transform: uppercase;
    padding: 0 1rem;
    position: relative;

    &:hover {
      .edit {
        opacity: 1;
      }
    }

    .edit {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 0px;
    }
  }
  ul {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    // border-top: 1px solid #f1f2f4;
    margin-top: 2rem;
    // padding: 0rem 1rem;
    span {
      color: #29394d;
    }

    li {
      padding: 0rem 1rem;
      color: #6d7f92;

      &:hover {
        .content_li .value svg {
          opacity: 1;
        }
      }

      .content_li {
        border-top: 1px solid #f1f2f4;
        padding: 1rem 0rem;
        .value {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .edit {
            cursor: pointer;
          }
          .valueContent {
            width: fit-content;
            .approval_num {
              display: block;
              margin-top: 0.5rem;
              input {
                border: none;
                letter-spacing: 0.15rem;
                background-color: transparent;
              }
            }
          }
          svg {
            opacity: 0;
          }
        }
      }
      .last {
        border-bottom: 1px solid #f1f2f4;
      }
    }
    .active_li {
      background-color: #f8f9fa;
    }
  }
}
.buttons {
  // margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    height: 2rem;
    width: 7.125rem;
    background: #06d9b1;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 0.75rem;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }
  .cancelBtn {
    width: 5.5rem;
    background: #f1f2f4;
    color: #6d7f92;
  }
}

.avatarBtns {
  padding: 0 1rem 1.5rem;
  margin-top: -0.5rem;
}
.subscriptionDetails {
  background: #edfaf5;
  border: 1px solid #dcf5ec;
  border-radius: 5px;
  padding: 1.25rem;
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #29394d;
    margin-bottom: 1rem;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
  }
  a {
    color: #6d7f92;
    text-decoration: underline;
  }
  &_top {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #29394d;
    margin-bottom: 1.5rem;
    span {
      font-weight: 500;
      display: block;
      margin-top: 5px;
    }
    .icon {
      width: 34px;
      height: 34px;
      background: #06d9b1;
      border: 2px solid #f1f2f4;
      border-radius: 50px;
      margin-right: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      i:before {
        color: #fff;
      }
    }
  }

  &_watch {
    border-top: 1px solid #dcf5ec;
    margin-top: 15px;
    padding-top: 15px;
    a {
      color: #871a0f;
    }

    p {
      color: #29394d;
    }
  }

  &_link {
    color: #6d7f92 !important;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 5px;
  }
}

.content {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 2rem;
}

.title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  font-weight: 500;
}

.subtitle {
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  margin-bottom: 1.25rem;
}

.box {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #d8dde2;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-size: 12px;
  line-height: 14px;

  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.appsItem {
  padding: 8px;
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_top {
    display: flex;
    align-items: center;

    img {
      margin: 0 15px;
      height: 34px;
    }
  }
  &_content {
    margin-top: 1rem;
  }
}

.dragHandler {
  font-size: 2rem;
  color: #b6bfc8;
  cursor: move;
}

.portalBloc {
  padding: 8px;
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_left {
      display: flex;
      align-items: center;
    }

    img {
      margin: 0 15px;
      height: 34px;
    }
  }
}

.mapbox {
  position: relative;
  z-index: 0;
  height: 300px;
  margin-bottom: 1rem;
}

.schedule {
  background-color: #fff;
  color: #3c4e64;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;

  &_row {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    border-bottom: 1px solid #f1f2f4;
  }

  &_header {
    font-weight: 500;
    text-align: center;
  }

  &_day {
    width: 20%;
    text-align: center;
  }

  &_hours {
    width: 32%;
  }

  &_action {
    width: 16%;
  }

  &_input {
    display: flex;
    align-items: center;

    span {
      margin: 0 10px;
    }

    input {
      background: #ffffff;
      border: 1px solid #b2bcc6;
      border-radius: 4px;
      padding: 7px 5px;
      font-size: 12px;
      line-height: 14px;
      color: #18a0fb;
      width: 50px;

      &:hover {
        border-color: #dfe2e6;
      }

      &:focus {
        border: 1px solid #18a0fb;
        background: #f3faff;
        box-shadow: none;
      }
    }
  }

  &_nowork {
    display: flex;
    align-items: center;
    margin-top: 10px;

    span {
      margin-left: 0.75rem;
    }
  }

  &_btn {
    border: 1px solid #c7e8fe;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    display: block;
    cursor: pointer;
    line-height: 14px;
    text-align: center;
    padding: 10px 5px;
    margin: 0 1rem 0 0;
    color: #18a0fb;

    &:hover {
      background-color: #18a0fb;
      color: #fff;
    }
  }
}

.services {
  position: relative;
  .list {
    max-height: 350px;
    overflow: scroll;
    margin-top: 1rem;
  }
}
.addService {
  border-radius: 5px;
  cursor: pointer;
  height: 160px;
  width: 133px;
  background: #ffffff;
  border: 1px solid #18a0fb;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #18a0fb;
    font-size: 50px;
  }
  &:hover {
    background: #18a0fb9a;
    i {
      color: #fff;
    }
  }
}

.mediaTitle {
  margin: 10px 10px 0;
}

.addMedia {
  // margin-top: 1rem;
  margin-left: auto;
}

.addBox {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  color: #18a0fb;
  background: #ffffff;

  &_icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #c7e8fe;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    font-size: 14px;

    i {
      font-weight: bold;
      transition: all 0.2s ease-in-out;
    }
  }
}

.rotate {
  transform: rotate(45deg);
}

.widgetBody {
  display: none;
  border-radius: 5px;
  border: 1px solid #c7e8fe;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  padding: 1rem;
  margin-top: 1rem;
  background-color: #f8f9fa;

  h3 {
    color: #29394d;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 0 1rem;
    text-transform: uppercase;
  }

  h3.apps {
    border-top: 1px solid #e1e1e1;
    margin-top: 1rem;
    padding-top: 1.5rem;
  }
}
.widgetContainer {
  display: flex;
  flex-wrap: wrap;
}
.widgetBloc {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #f8f9fa;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

  &_icon {
    font-size: 18px;
    margin-right: 1rem;
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &_label {
    margin-bottom: 5px;
  }
}
.disableBtn {
  opacity: 0.7;
}
.mediasList {
  max-height: 400px;
  overflow-y: scroll;
  margin: 0 -10px;
  margin-top: 1rem;
  padding: 0 10px;
}
.mediaCont {
  padding: 0 5px;
  margin-bottom: 10px;
}
.uploadingMedia {
  position: relative;
  height: 8.75rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: $v2-box-shadow;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f4;
  border-radius: 5px;

  .uploadingMediaOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.switchGroup {
  margin: 1.313rem 0;
}
.filter {
  padding-left: 1rem;
  border-right: 1px solid #d8dde2;
}
.configLabel {
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
}
.switches {
  .checkbox_disabled {
    color: #ccc;

    .checkbox {
      cursor: not-allowed;
    }
  }
  li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;

    .checkbox {
      cursor: pointer;
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 100%;
      background: #f8f9fa;
      border: 2px solid #b2bcc6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.75rem;
      &_active {
        background: #18a0fb;
        border: 2px solid #18a0fb;
      }
    }
  }
}

.marginBottom {
  margin-bottom: 1rem;
}

.actionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 10px rgb(41 57 77 / 10%);

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.manage_account {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  height: 44px;
  border: none;
  outline: none;
  background-color: #18a0fb;
  color: #fff;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}

@media only screen and (max-width: 992px) {
  .mobileBanner {
    display: block;
    background-color: #f7953e;
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
  }
}
