.card {
  // background: #F1F2F4;
  border-radius: 8px;
  padding: 1.25rem;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  background: #f1f2f4;
  border-radius: 8px;

  &.installed {
    background: #ffffff;
    border: 1px solid #d8dde2;
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.65rem;
    h3 {
      margin: 0 auto 0 1rem;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #29394d;
    }
    .default {
      margin: 0 0 0.65rem 0;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #06d9b1;

      i {
        margin-right: 4px;
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #29394d;
    margin-bottom: 1.85rem;

    a {
      color: #29394d;
    }
  }

  .details {
    background: #f3faff;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: #29394d;
    margin-bottom: 10px;

    li {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }
    span {
      display: block;
      position: relative;
      background: #f3faff;
      border-radius: 50px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #18a0fb;
      padding: 0.5rem 0.85rem;

      &:before {
        position: absolute;
        top: 13px;
        left: 0;
        content: "";
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 23px;
        background-color: #18a0fb;
      }
    }
  }
  .accordion {
    > div {
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid #d8dde2;
      border-radius: 5px;
      padding: 0.7rem 0.7rem 0.7rem 1rem;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
      margin-bottom: 1.5rem;
      cursor: pointer;
      span {
        margin-left: auto;
        color: #6d7f92;
        i:before {
          margin-left: 4px;
          color: #6d7f92;
        }
      }

      &:hover,
      &.accordion_active {
        border-color: #18a0fb;
        box-shadow: 0 4px 4px rgba(41, 57, 77, 0.1);
      }
    }
    ul {
      border-top: 1px solid #f1f2f4;
      margin-bottom: 2rem;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
      li {
        padding: 1rem 0;
        border-bottom: 1px solid #f1f2f4;
        > div {
          margin-bottom: 0.625rem;
          h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #29394d;
            margin: 0 auto 0 10px;
          }
          .newsletter_tag {
            cursor: pointer;
            margin-right: 1em;
            font-weight: 500;
            color: #18a0fb;
            background: #f3faff;
            padding: 3px 9px;
            border-radius: 50px;
            text-transform: lowercase;
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #6d7f92;
          margin-bottom: 2rem;
        }
      }
      .version {
        background: #f3faff;
        border-radius: 50px;
        font-weight: 500;
        color: #18a0fb;
        padding: 6px 15px;
      }
    }
  }
  .footer {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    border-top: 1px solid #d8dde2;

    i {
      margin-right: 6px;
    }

    > div {
      color: #6d7f92;
    }
    .install {
      color: #06d9b1;
      i:before {
        color: #06d9b1;
      }
    }
  }
}

.userRights {
  cursor: pointer;
}
