.pageTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.pageHelpTitle {
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  margin-bottom: 1.5rem;
}

.header_btns {
  display: flex;
  justify-content: center;
  margin: 1rem 0 2rem;
}

.header_btn {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 206px;
  height: 54px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #18a0fb;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: box-shadow 0.2s, transform 0.2s;

  &:hover {
    box-shadow: 2px 2px 11px rgba(41, 57, 77, 0.05);
    transform: scale(0.99);
  }

  &_active {
    background: linear-gradient(180deg, #2495e1 0%, #18a0fb 100%);
    border: 1px solid #eef6ff;
    color: #fff;
  }
}

.bubbles {
  position: fixed;
  top: 16rem;
  right: -134px;
  color: #29394d;
  font-weight: 500;
  font-size: 16px;
  z-index: 2;
  transition: 0.6s all ease-in-out;

  & > div {
    background: #fff;
    box-shadow: 0px 4px 5px rgba(60, 78, 100, 0.2);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    position: relative;
    width: 205px;
    height: 62px;
    border-radius: 31px 0px 0px 31px;
    padding: 0 1rem;
    transform: translate(0);

    font-size: 16px;
    line-height: 19px;

    &.disabled {
      cursor: initial;
      color: #ccc;

      &:after {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        content: "";
        border-radius: 31px 0px 0px 31px;
        background: rgba(41, 57, 77, 0.1);
      }
    }

    i {
      font-size: 1.5rem;
      z-index: 2;
      padding: 0 0.1rem 0 0.3rem;
    }

    span,
    div {
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
    }

    &:not(.disabled):hover,
    &.active {
      transform: translate(-110px);
      background: #2495e1;
      box-shadow: 0px 4px 5px rgba(109, 127, 146, 0.2);
      color: #fff;
      width: 220px;

      span,
      div {
        opacity: 1;
      }
    }
  }
}

.filterBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.filter {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }
}

.inputSearch {
  display: flex;
  background: #fff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  border-radius: 30px;
  height: 32px;
  width: 300px;
  align-items: center;

  &_icon {
    color: #b7bdc3;
    cursor: pointer;
    font-size: 1.2rem;
    display: inherit;
    margin: auto 1rem;
  }

  input {
    width: 240px;
    border: none;
    outline: none;
    margin: 0;
    color: #3c4e64;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;

    i {
      cursor: pointer;
      color: #6d7f92;

      &.iconActive {
        color: #29394d;
      }
    }

    .sortBox {
      margin-left: 5px;
    }
  }

  tr {
    background: #fafbfb;
    border-bottom: 2px solid #fff;
    border-radius: 2px;
  }

  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;

    &.centerCell {
      text-align: center;
    }

    .cellIcon {
      transform: rotate(90deg);
      cursor: pointer;
      font-size: 14px;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: #f1f2f4;
      }

      &.active {
        background-color: #18a0fb;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.status {
  border-radius: 4px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  &.pending {
    background: #ffecbf;
    color: #e39f00;
  }

  &.validated {
    background: #dcf5ec;
    color: #02af8e;
  }

  &.rejected {
    background: #ffd7da;
    color: #fe3745;
  }
}

.nowrap {
  white-space: nowrap;
}

.dropDownContainer {
  position: relative;
}
.menuDropdown {
  position: absolute;
  right: 2.7rem;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #18a0fb;
  border-radius: 4px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  background: #fff;
  z-index: 1005;
  width: 200px;

  &:before {
    content: "";
    right: -10px;
    border-color: transparent transparent transparent #18a0fb;
    border-style: solid;
    border-width: 7px 0 7px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  li {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    line-height: 1.6;

    &:hover {
      background: #f8f9fa;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.apps {
  display: flex;
  margin-left: 10px;

  &_item {
    margin-left: -10px;
    position: relative;

    &:hover {
      span {
        display: block;
      }

      img {
        width: 35px;
        height: 35px;
        -webkit-transition: all 0.3s;
        -webkit-transform-origin: 50% 100%;
      }
    }

    span {
      display: none;
      position: absolute;
      top: 35px;
      left: 0;
      z-index: 99;
      color: #fff;
      background-color: rgb(40, 57, 76);
      padding: 4px 10px;
      border-radius: 4px;
      white-space: nowrap;
      border: 0.5px solid rgba(150, 150, 150, 0.5);
      box-shadow: 0 0 0px 1px #000;
    }

    img {
      border: 2px solid #f1f2f4;
      border-radius: 50%;
      width: 25px;
      height: 25px;
    }
  }
}

.tabs_item {
  display: flex;
  flex-wrap: wrap;

  span {
    background-color: #b9e7f8;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 5px;
  }
}

.action {
  cursor: pointer;
  font-size: 14px;
}

.default_tag {
  background-color: #b9e7f8;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 5px;
}
