.positioning {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .popOver {
    width: 600px;
    min-height: 600px;
    max-height: 90vh;
    // overflow: scroll;
    // transform: translateY(-50%);
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #18a0fb;
    box-sizing: border-box;
    border-radius: 5px;
    z-index: 121;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    label {
        padding-left: 0 !important;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.5rem;
      padding: 0 1.25rem;
      border: 1px solid #18a0fb;
      border-radius: 4px;
      font-size: 0.875rem;
      text-transform: uppercase;
      font-weight: 600;
      color: #fff;
      background-color: #18a0fb;
      width: 100%;
    }
  }
  
  // .close {
  //   text-align: right;
  //   cursor: pointer;
  
  //   i {
  //     color: #29394d;
  //   }
  // }
  
  .actions {
    margin-top: 20px;
  }
  
  .formRow {
    display: flex;
    justify-content: space-between;
  
    > div {
      width: 49%;
    }
  }
  
  .select_app_option {
    display: flex;
    align-items: center;
    gap: 10px;
  }

.error {
    color: #d9534f;
    font-size: 12px;
    font-weight: normal;
    display: block;
    height: 20px;
}

.btTab {
    cursor: pointer;
    border-radius: 4px;
    background-color: #18a0fb;
    display: inline;
    padding: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 0 16px auto;
    width: 70px;
    align-self: end;
    i {
        font-size: 16px;
        color: white;
    }
    > div > div {
    width: 20px;
    height: 2px;
    background-color: white;
    margin: 3px 0;
    }
}
.title {
  margin-top:10px;
  margin-bottom:10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  ul {
    display: none;
    font-size: 12px;
    position: absolute;
    top: 0; 
    right: 0;
  }
  .info {
    position: relative;
    cursor: pointer;
    font-size: 14px;
  }
  .info:hover ul{
    display: block;
    background: white;
    border-radius: 5px;
    border: 1px solid #18a0fb;
    padding: 4px 10px 4px 20px;
    z-index: 999;
    li {
      list-style: disc;
      text-wrap: nowrap;
    }
  }
}



.close {
  position: absolute;
  top: -1rem;
  right: -1rem;
  transform: rotate(90deg);
  cursor: pointer;
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #18a0fb;
  color: #fff;
  font-size: 12px;
  z-index: 999;
}

  