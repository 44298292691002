.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1005 !important;
  background-color: rgba(41, 57, 77, 0.4);
}
.modalContentAfterOpen {
  .modal {
    top: 0;
    opacity: 1;
  }
}
.modalContentBeforeClose {
  .modal {
    top: 50%;
    opacity: 0;
  }
}
.modalContent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
  background: transparent;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;
  top: 50%;
  width: 700px;
  opacity: 0;
  background: #f8f9fa;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  height: 60px;
  border-radius: 5px 5px 0px 0px;
  font-size: 22px;
  line-height: 26px;
  color: #2495e1;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.controls {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem;
}

.authorAvatar {
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  width: 8rem;
  height: 8rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0 4px 4px rgba(41, 57, 77, 0.1);
  border: 2px solid #2495e1;
}

.emptyAvatar {
  border: none;
  background: linear-gradient(
    to bottom right,
    #ddd,
    #ccc,
    #c5c5c5,
    #bbb,
    #b5b5b5,
    #aaa
  );
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1),
    0 0 0 1px rgba(44, 43, 63, 0.13);
  span {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
  }
}

.canvas {
  width: 100%;
  display: flex;
}

.emptyCanvas {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sliders {
  width: 100%;
  padding: 0 2rem 2rem;
}

.slider {
  // display: flex;
  // flex-wrap: wrap;
  // width: 50%;
  margin-top: 2rem;

  &__top {
    display: flex;
  }

  &__input,
  input {
    width: 100%;
  }

  &__name {
    margin-right: auto;
  }
}
