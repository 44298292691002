// modal styling
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.modal_header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  background: #f3faff;
  border-bottom: 1px solid rgba(199, 232, 254, 0.5);
  border-radius: 0px 0px 5px 5px;
  font-size: 20px;
  line-height: 23px;
  color: #2495e1;

  &_sub {
    font-size: 18px;
    margin-left: 10px;
  }
}
.modal_icon {
  margin-right: 1rem;
}
.modal_body {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 39rem;
  overflow-y: scroll;
  padding: 20px 42px 42px 42px;
  border-bottom: 1px solid #f1f2f4;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.content {
  padding: 1rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
}

.appsItem {
  padding: 8px;
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 9999;
  }

  &_tabs {
    .appsItem_top {
      min-height: 40px;
    }
  }

  &_top {
    display: flex;
    align-items: center;

    img {
      margin: 0 15px;
      height: 34px;
    }
  }
  &_content {
    margin-top: 1rem;
  }
}

.dragHandler {
  font-size: 2rem;
  color: #b6bfc8;
  cursor: move;
}
