.leftSide {
  background-color: #18a0fb;
  color: #ffffff;
  height: 100vh;
  padding: 2rem 4rem;
  font-size: 14px;
  line-height: 16px;
  position: relative;

  &_headTitle {
    margin: 2rem 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }
}

.rightSide {
  padding: 2rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}


.content {
  width: 50%;
  margin: 4rem auto;
  p {
    margin-bottom: 100px;
    font-size: 20px;;
  }
}

.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;
  margin-bottom: 2rem;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 2rem;
  justify-content: space-between;
}