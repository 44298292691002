.dockContainer {
  position: fixed;
  top: 150px;
  text-align: center;
  left: 0;
  z-index: 104;
  width: 65px;
  padding: 8px 8px 8px 12px;
  background: rgba(40, 57, 76, 0.2);
  border-radius: 0 10px 10px 0;

  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    list-style-type: none;
    display: inline-block;
    position: relative;
    margin: 5px 0;

    &.active {
      &:before {
        position: absolute;
        top: 22px;
        left: -10px;
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: rgba(40, 57, 76, 1);
      }
    }

    img {
      width: 50px;
      height: 50px;
      -webkit-transition: all 0.3s;
      -webkit-transform-origin: 50% 100%;
    }

    &:hover {
      img {
        -webkit-transform: scale(1.5);
        margin: 1rem 1rem;
      }
      span {
        display: block;
        color: #fff;
      }
    }

    span {
      display: none;
      position: absolute;
      top: 18px;
      left: 85px;
      background-color: rgba(40, 57, 76, 1);
      padding: 4px 10px;
      border-radius: 4px;
      white-space: nowrap;
      border: 0.5px solid rgba(150, 150, 150, 0.5);
      box-shadow: 0 0 0px 1px #000;
    }
  }
}

.dockContainer li:hover + li img {
  -webkit-transform: scale(1.25);
  margin: 0 1em;
}

@media screen and (max-width: 400px) {
  .dockContainer {
    width: 100%;
    height: fit-content;
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    border-radius: 10px 10px 0px 0;
    background-color: #e1e2e4;
    ul {
      flex-direction: row;
    }
    li {
      width: fit-content;
      flex: 1;
      margin: 1px 5px;
      padding-bottom: 0.5rem;
      display: flex;
      justify-content: center;

      &.active {
        &:before {
          top: unset;
          bottom: -5px;
          left: 50%;
          transform: translate(-50%);
        }
      }
      &:hover {
        img {
          transform: none;
          margin: 0;
        }
        span {
          padding: 0;
          display: none;
        }
      }
      &:hover + li img {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}
