.faq_section {
  padding: 2rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_title {
  font-weight: 600;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1rem;
  text-align: center;
}

.description {
  font-size: 1rem;
  line-height: 120%;
  text-align: center;
  margin-bottom: 2rem;
}

.list {
  width: 100%;
  .item {
    border-top: 1px solid #000;
    padding: 1rem 0;
    cursor: pointer;
    .question {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: 700;
      line-height: 120%;
      span {
        display: block;
        width: 95%;
      }
    }
    .answer {
      display: none;
    }

    &:last-child {
      border-bottom: 1px solid #000;
    }
  }
  .open {
    .question svg {
      transform: rotate(180deg);
    }
    .answer {
      display: block;
      margin-top: 1rem;
    }
  }
}
