.portal {
  background: #fafbfb;
  color: #3c4e64;
}
.portalContent {
  padding: 0 4rem 2rem;
  section {
    padding: 1.3rem 0;
  }
}
.header {
  .content {
    background-color: #fff;
    padding: 1% 1.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f1f2f4;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    .logo {
      max-width: 15%;
      transition: 0.3s max-width ease-in-out;
      height: 70px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .subMenu {
      display: flex;
      background: #f1f2f4;
      border: 1px solid #d8dde2;
      border-radius: 50px;
      padding: 4px;

      &_btn {
        height: 40px;
        width: 158px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        border-radius: 50px;

        &.activated {
          background: #18a0fb;
          box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
          color: #fff;
        }

        &:hover {
          color: #29394d;
          background-color: #fff;
        }
      }
      :not(:last-child) {
        margin-right: 1%;
      }
    }
  }
  &_fixed {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 101;
    width: 100%;
    padding: 1rem 4rem !important;

    .logo {
      max-width: 10% !important;
    }
    & + .portalInfos {
      margin-top: 7.625rem;
    }
  }
}

.portalInfos {
  position: relative;

  .mediaCaroussel {
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    padding-top: 0;
    height: 50rem;

    li {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .slick_dots {
    position: absolute;
    bottom: 11.125rem;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      button {
        border: 0;
        background: transparent;
        display: block;
        height: 10px;
        width: 10px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer;
        background-color: #9a9a9a;
        border-radius: 50%;
        box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);

        &:hover,
        &:focus {
          outline: none;
          &:before {
            opacity: 1;
          }
        }
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 20px;
          height: 20px;
          font-size: 4rem;
          line-height: 20px;
          text-align: center;
          color: #000;
          opacity: 0.25;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
    :global li.slick-active button {
      box-shadow: none;
      background-color: #18a0fb;
      padding: 0px 10px;
      border-radius: 25px;
      position: relative;
      left: -5px;
    }
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 37rem;
    padding: 1.375rem 4.625rem;
    padding-bottom: 0px;
  }

  .fiduciaryInfos {
    margin-top: 3.75rem;
    width: 100%;
    height: 25rem;
    background: rgba(41, 57, 77, 0.1);
    border: 1px solid #6d7f92;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    padding: 4.063rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    .title {
      font-weight: 700;
      font-size: 2.25rem;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 1.125rem;
    }
    .description {
      display: block;
      font-weight: 400;
      font-size: 1rem;
      line-height: 140%;
      text-align: center;
      text-transform: capitalize;
      width: 44.563rem;
      margin-bottom: 2rem;
    }
    .flex {
      width: 44.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .infoDiv {
      width: 21.625rem;
      height: 8.063rem;
      background: rgba(41, 57, 77, 0.5);
      border-radius: 5px;
      padding: 1.25rem 1.875rem;
      ul li {
        display: flex;
        align-items: center;

        span {
          display: block;
          margin-left: 0.5rem;
        }
        i::before {
          color: #fff;
        }
      }
    }
  }
}

.stickyBanner {
  height: 300px;
  position: sticky;
  top: 145px;
  margin-bottom: 58px;
}

.leftBanner {
  background: #f1f2f4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 305px;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #18a0fb;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #29394d;
    margin-top: 5%;
    width: 76%;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #6d7f92;
    margin-top: 5%;
  }
  .buttons {
    width: 100%;
    margin-top: 4%;
    button {
      border: none;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #29394d;
      text-transform: uppercase;
      padding: 0% 4%;
      width: fit-content;
      margin-bottom: 4%;
      margin-right: 4%;
      float: left;
      cursor: pointer;
    }
  }
}

.newsList {
  flex: 1 1;
  padding-left: 2%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .articlesList {
    width: 100%;

    :global .cell {
      padding-right: 1%;
      padding-bottom: 1%;
    }
  }
  .view-more {
    button {
      padding: 0% 2%;
      margin: 0% 1%;
    }
    hr {
      width: 350px;
    }
  }
}

.teamListContainer {
  padding-left: 2%;
  display: flex;
  flex-direction: column;

  .teamList {
    width: 100%;
    margin-bottom: 5%;
    // .cell {
    //   padding-right: 1%;
    //   padding-bottom: 2%;
    // }

    .user {
      height: 334px;
      width: 222px;
      background-color: #f1f2f4;

      &Img {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 252px;
      }
      .officialInfo {
        height: calc(100% - 252px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #29394d;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #6d7f92;
        }
      }
    }
  }
}

.viewMore {
  width: 100%;
  display: flex;
  align-items: center;
  hr {
    width: 370px;
    border: 1px solid #b2bcc6;
  }
  button {
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 50px;
    width: 140px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0% 2.3%;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #29394d;
    cursor: pointer;
    i {
      font-weight: 900;
    }
  }
}

.contactSection {
  :global .cell {
    padding-right: 2%;
  }
  .contactMap {
    height: 343px;
    margin-top: 1.25rem;
    border-radius: 5px;
  }
  .info {
    padding-right: 1%;
  }
  .contactForm {
    height: 44.25rem;
    background: #f1f2f4;
    border-radius: 5px;
    padding: 3.188rem 2.813rem;
    h5 {
      margin-bottom: 2.125rem;
      font-weight: 700;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .formInput {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.875rem;
      label {
        margin-bottom: 0.875rem;
      }
      input,
      textarea {
        resize: none;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #b2bcc6;
        padding: 0 0.5rem;
      }
      input {
        height: 2.75rem;
      }
      textarea {
        height: 11.625rem;
        padding: 0.5rem;
      }
      .small {
        width: 16.25rem;
      }
      .error {
        font-size: 0.75rem;
        color: red;
        display: block;
        height: 0.5rem;
      }
      .errorBorder {
        border-color: red;
      }
    }
    .actions {
      display: flex;
      justify-content: center;
    }
    button {
      cursor: pointer;
      width: 13rem;
      height: 2.75rem;
      background: #18a0fb;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      color: #fff;
      font-weight: 500;
      font-size: 1rem;
      border: none;
    }
  }

  .organizationSchedule {
    border-radius: 5px;
    padding: 8% 6%;
    padding-bottom: 4%;
    background: rgb(241, 242, 244);
    height: 343px;

    h5 {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #18a0fb;
      margin-bottom: 10px;
    }
    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;
      color: #29394d;
      margin-bottom: 5%;
    }
    table {
      margin: 0;
      width: 100%;

      tbody {
        background-color: transparent;
        tr {
          background-color: transparent;
          td {
            font-weight: 400;
            font-size: 14px;
            line-height: 203%;
            letter-spacing: 0.02em;
            color: #3c4e64;
            padding: 1%;
            width: fit-content;
          }
          .closed {
            color: #6d7f92;
          }
          .day {
            color: #18a0fb;
            padding-right: 14px;
          }
        }
      }
    }
    .list1 {
      margin-top: 1.375rem;
      font-size: 0.875rem;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        span {
          display: block;
          margin-left: 0.5rem;
        }
      }
    }
    .list2 {
      margin-top: 1.313rem;
      color: #6d7f92;
      font-size: 0.875rem;
    }
  }
}

.hide {
  display: none;
}

.servicesContent .listContainer {
  padding-left: 2%;
  display: flex;
  flex-direction: column;

  .servicesList {
    width: 100%;
    margin-bottom: 5%;

    :global .cell {
      padding-right: 1%;
      padding-bottom: 1%;
    }
  }
}

.footer {
  height: 44px;
  background: #f1f2f4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  color: #6d7f92;
}

.fiduciaryNews {
  margin-bottom: 3rem;
  margin-top: -7rem;
  &_noMargin {
    margin-top: 0;
  }
}

.specialtySection {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 -4rem;
  margin-bottom: 4rem;
  color: #29394d;
  .title {
    color: #29394d;
    font-weight: 700;
    font-size: 2rem;
    line-height: 140%;
    margin-bottom: 0.625rem;
  }
  .description {
    width: 55.375rem;
    font-size: 0.875rem;
    line-height: 140%;
    color: #6d7f92;
    text-align: center;
  }
  .types {
    display: flex;
    align-items: center;
    margin-bottom: 3.75rem;
    margin-top: 2.5rem;
    li {
      cursor: pointer;
      padding: 0 1rem;
      height: 2.125rem;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.25rem;
      &:last-child {
        margin-right: 0;
      }
    }
    .activeType {
      background-color: #18a0fb;
      color: #fff;
    }
  }
  .specsList {
    width: 100%;
    padding: 0 5rem;
    .viewMore {
      button {
        width: 140px;
        padding: 0 1.5rem;
        margin: 0 2.375rem;
      }
      hr {
        width: 532px;
      }
    }
  }
}
.specialtyCard {
  width: 19.063rem;
  padding: 1.25rem;
  border-radius: 5px;

  margin-bottom: 1.25rem;
  transition: all 0.5s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  }
  .iconContainer {
    margin-bottom: 2.5rem;
    width: 70px;
    height: 35px;
    border: 1px solid #29394d;
    border-bottom: transparent;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconCircle {
      height: 54px;
      width: 54px;

      border-radius: 100%;
      margin-top: 2.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 2rem;
      }
    }
  }
  .specTitle {
    padding: 0.438rem 1.188rem;
    max-width: 13rem;
    border-left: 1px solid #29394d;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 140%;
    text-transform: capitalize;
    margin-bottom: 1.25rem;
  }
  .favoritesList {
    li {
      position: relative;
      border-left: 1px solid #29394d;
      padding: 1.125rem 1.375rem;

      border-radius: 0px 5px 5px 0px;
      width: 100%;
      margin-bottom: 0.5rem;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      &:last-child {
        margin-bottom: 0;
      }
      .svg {
        position: absolute;
        bottom: -4px;
        right: 10px;
        z-index: 1;
      }
      span {
        position: relative;
        display: block;
        width: 100%;
        z-index: 2;
      }
    }
  }
  .othersList {
    margin: 0.688rem 0 0.75rem 0;
    padding-left: 1.5rem;
    line-height: 16px;
    color: #000000;
    li {
      list-style-type: disc;
      margin-bottom: 0.625rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .showMoreBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 0.75rem;
    color: #6d7f92;
    cursor: pointer;
    svg {
      margin-left: 0.5rem;
    }
    &_active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.subHeader_container {
  margin-top: 1rem;
  padding: 0 4.625rem;
}

@media screen and (max-width: 1024px) {
  .portal {
    padding: 2%;
  }
  .header {
    .content {
      margin-top: -2%;
      .subMenu {
        &_btn {
          height: 2.5rem;
          width: 7.5rem;
        }
      }
    }
    &_fixed {
      margin-top: 2%;
      padding: 0% !important;
    }
  }

  .teamListContainer .teamList :global .cell {
    padding-right: 1%;
    padding-bottom: 2%;
    width: 33%;
  }
  .viewMore {
    button {
      padding: 0% 3%;
      margin: 0% 3%;
    }
    hr {
      width: 250px;
    }
  }
  .fiduciaryNews {
    display: flex;
    flex-direction: column;

    .leftBanner {
      width: 100%;
      h2,
      p,
      .buttons {
        width: 100%;
        margin-top: 2%;
      }
      .buttons {
        button {
          margin-right: 2%;
          margin-bottom: 2%;
        }
      }
    }
    .newsList {
      padding-left: 0%;
      padding-top: 4%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .articlesList {
        width: 100%;
        margin-bottom: 5%;
        :global .cell {
          padding-right: 1%;
          padding-bottom: 1%;
        }
      }
      .viewMore {
        button {
          padding: 0% 2%;
          margin: 0% 1%;
        }
        hr {
          width: 350px;
        }
      }
    }
  }

  .contactContainer {
    .contactMap {
      margin-bottom: 1rem;
    }
    .organizationSchedule {
      height: 100% !important;
    }
    .fiduciaryInfos {
      padding-right: 1%;
      padding-top: 4%;
      height: 100% !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .portal {
    padding: 2%;
  }
  .header {
    .content {
      margin-top: -2%;
      .subMenu {
        &_btn {
          height: 2rem;
          width: 7rem;
        }
      }
    }
    &_fixed {
      margin-top: 2%;
      padding: 0%;
    }
  }
  .portalInfos {
    .fiduciaryInfos {
      top: 10%;
      right: 3%;
    }
  }
  .viewMore {
    button {
      padding: 0% 4%;
      margin: 0% 4%;
    }
    hr {
      width: 150px;
    }
  }
  .contactContainer {
    .organizationSchedule {
      height: 100% !important;
    }
    .fiduciaryInfos {
      padding-right: 1%;
      padding-top: 4%;
      height: 100% !important;
    }

    .contactMap {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .teamListContainer .teamList :global .cell {
    padding-right: 1%;
    padding-bottom: 2%;
    width: 50%;
  }
}

@media screen and (max-width: 440px) {
  .portal {
    padding: 1%;
  }
  .header {
    padding-top: 0%;
    .content {
      border: none;
      box-shadow: none;
      .logo {
        display: none;
      }
      .subMenu {
        width: 100%;
        &_btn {
          width: 25%;
          font-size: 12px;
          height: 2rem;
        }
      }
    }
    &_fixed {
      padding: 0%;
    }
  }
  .portalInfos {
    .fiduciaryInfos {
      top: 8%;
      right: 3%;
    }
  }
  .leftBanner {
    padding: 6%;
  }
  .newsList {
    padding-right: 0%;
    padding-top: 8%;

    .articlesList {
      :global .cell {
        padding-right: 0%;
        padding-bottom: 6%;
      }
    }
  }
  .viewMore {
    button {
      padding: 0% 4%;
      margin: 0% 4%;
    }
    hr {
      width: 100px;
    }
  }
  .teamListContainer {
    padding-top: 8%;

    .teamList :global .cell {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 6%;
    }
  }
  .servicesContent .listContainer {
    padding-top: 8%;
    .servicesList :global .cell {
      padding-bottom: 6%;
    }
  }
}
