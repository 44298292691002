.contact_section {
  padding: 2rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_title {
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}

.list {
  margin: 2rem -1rem;
  margin-left: -3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  .cardCont {
    padding: 0 0.5rem;
  }
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
  .icon {
    margin-bottom: 0.5rem;
    svg {
      height: 2rem;
    }
  }
  .title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 130%;
    text-transform: capitalize;
  }
  .value {
    line-height: 150%;
    text-decoration-line: underline;
    margin-top: 1rem;
  }
  .schedule {
    margin: 0 -2rem;
    margin-top: 1.5rem;
    line-height: 150%;
    .day {
      text-align: right;
      font-weight: 600;
      padding-right: 0.5rem;
    }
    .morning {
      padding-right: 0.5rem;
    }
    .closed {
      color: #6e6d6c;
    }
  }
}
.map {
  height: 20rem;
  width: 100%;
}

.flex {
  display: flex;
  align-items: flex-start;
  width: 100%;
  .map {
    height: 45.875rem;
    flex-grow: 1;
    margin-right: 5rem;
  }
  .form {
    min-width: 40rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      margin-bottom: 2rem;
    }
    .formInput {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      input,
      textarea {
        margin-top: 0.5rem;
        width: 100%;
        outline: none;
        border: 1px solid #000;
        padding: 12px;
      }
      textarea {
        resize: none;
        height: 11.25rem;
      }
      .error {
        font-size: 12px;
        display: block;
        margin-top: 0.5rem;
        color: red;
      }
    }
    .formCheck {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      margin-bottom: 1.5rem;
      .check {
        height: 18px;
        width: 18px;
        border: 1px solid #000;
        background-color: #fff;
        margin-right: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .checked {
        background-color: #000;
        color: #fff;
      }
    }
    .btn {
      height: 3rem;
      background-color: #000;
      color: #fff;
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .disabled {
      width: 6rem;
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}
