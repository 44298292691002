.service {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6% 5%;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 5px;
  // cursor: pointer;
  &.checked {
    &::before {
      position: absolute;
      border-radius: 5px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      content: "";
      background: rgba(36, 149, 225, 0.6);
    }
    &::after {
      text-align: center;
      line-height: 40px;
      width: 40px;
      height: 40px;
      position: absolute;
      font-family: "tamtam";
      color: #fff;
      content: "\e901";
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #1e9aed;
      box-sizing: border-box;
      border-radius: 50px;
    }
    span.check {
      background-color: #18a0fb;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 8px 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.53866 11.9999C4.16564 11.9999 3.81284 11.8254 3.58604 11.5261L0.522949 7.48089C0.124549 6.95477 0.228264 6.20563 0.754378 5.80706C1.28135 5.40832 2.02998 5.51272 2.42855 6.03849L4.44318 8.69872L9.51009 0.563059C9.85895 0.00334478 10.5956 -0.167912 11.1565 0.180431C11.7167 0.529116 11.8878 1.26626 11.5388 1.82649L5.55318 11.4368C5.34524 11.7709 4.98626 11.9811 4.59318 11.9989C4.57484 11.9996 4.55684 11.9999 4.53866 11.9999Z' fill='white'/%3E%3C/svg%3E");
    }
  }
  .icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #29394d;
    display: flex;
    align-items: center;
    justify-content: center;
    .blueCircle {
      width: 75%;
      height: 75%;
      background-color: #c7e8fe;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  i {
    font-size: 35px;
    color: #29394d;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #29394d;
    margin: 0;
    margin-top: 5%;
    text-align: center;
    width: 100%;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #6d7f92;
    margin-top: 4%;
    margin-bottom: 0%;
  }
  .editDelete {
    position: absolute;
    top: 0%;
    left: 0%;
    background-color: #18a0fbbd;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    display: none;
    align-items: center;
    justify-content: space-evenly;
    svg {
      color: white;
      cursor: pointer;
    }
  }
  &:hover {
    .editDelete {
      display: flex;
    }
    button {
      i {
        color: #fff;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }

  button {
    border: 0;
    background-color: transparent;
  }
}
