.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #29394d;
  margin-bottom: 2rem;
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(41, 57, 77, 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;

  &.hide {
    display: none;
  }
}

.sidebar {
  background-color: #f8f9fa;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 62%;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: 0.5s all ease-in-out;
  padding: 2rem;
  overflow-y: scroll;
  overflow-x: hidden;

  &.display {
    transform: translateX(0);
    box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  }
}

.close {
  text-align: right;
  cursor: pointer;
}

.label {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3c4e64;
  margin-bottom: 0.6rem;
  text-transform: initial;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 4rem 0 0;
  padding-top: 1rem;
  border-top: 1px solid #eaedf0;

  &__cancel {
    width: 30%;
    margin-right: 10px;
    justify-content: center;
  }

  &__ok {
    width: 50%;
    justify-content: center;
  }
}

.formRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formColumn {
  width: 48%;
}

.formControl {
  margin-bottom: 1.5rem;
}

.formLabel {
  display: block;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: capitalize !important;
}

.formLabelFlex {
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.formInput {
  display: block;
  width: 100%;
  padding: 2px 10px;
  height: 38px !important;
  border: 1px solid #ced4db;
  border-radius: 5px;

  &:focus {
    border-color: #18a0fb;
  }
}

.selectInput {
  input {
    height: 0;
    margin: 0;
  }
}

.themeCover {
  position: relative;
  height: 180px;
  width: 100%;
  color: #b2bcc6;
  font-size: 12px;
  line-height: 14px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px dashed #b2bcc6;
  border-radius: 5px;
}

.coverImg {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  cursor: move;
}

.formSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
}

.changeImg {
  margin-bottom: 1rem;
}

@media screen and (max-width: 39.9375em) {
  .sidebar {
    left: 0%;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .sidebar {
    left: 40%;
  }
}

.iconSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  border-radius: 5px;
}

.selectIconArea {
  background-color: #f1f2f4;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 1rem 0;
  cursor: pointer;
}

.selectIcon {
  font-weight: 500;
  border: 1px dashed #ccc;
  color: #6d7f92;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 8.75rem;
  height: 6.5rem;
  text-align: center;
  font-size: 0.75rem;
  i {
    font-size: 3rem;
    color: #18a0fb;
    opacity: 0.7;
  }
  p {
    margin: 0;
    font-size: 0.825rem;
    margin-top: 0.5rem;
    font-weight: 600;
    color: #18a0fb;
    text-transform: capitalize;
  }
  &.icon-selected {
    border: none;
    span {
      display: none;
    }
    &:hover {
      p {
        display: none;
      }
      border: 2px dashed #ccc;
      span {
        display: block;
      }
    }
  }
}
.sidebarIcon {
  left: 0%;
}
