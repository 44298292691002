.container {
  position: relative;
}

.actions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  display: flex;
  li {
    background-color: #18a0fb;
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    color: #fff;
    cursor: pointer;
    position: relative;
    svg {
      height: 1rem;
    }
  }
  .hide::after {
    content: " ";
    border-left: 2px solid #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 47%;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.hidden {
  opacity: 0.5;
}
.content {
  border: 1px dashed silver;
}
