.media {
  height: 8.75rem;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.3);
  position: relative;
  border-radius: 5px;
  .docs,
  .mediaContent {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }
  &:hover {
    .overlay {
      opacity: 1;
      z-index: 3;
    }
  }
  &.checked {
    cursor: pointer;
    &::before {
      position: absolute;
      border-radius: 5px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      content: "";
      background: rgba(36, 149, 225, 0.3);
    }
    &::after {
      text-align: center;
      line-height: 40px;
      width: 40px;
      height: 40px;
      position: absolute;
      font-family: "tamtam";
      color: #fff;
      content: "\e901";
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #1e9aed;
      box-sizing: border-box;
      border-radius: 50px;
    }
    span.check {
      background-color: #18a0fb;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 8px 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.53866 11.9999C4.16564 11.9999 3.81284 11.8254 3.58604 11.5261L0.522949 7.48089C0.124549 6.95477 0.228264 6.20563 0.754378 5.80706C1.28135 5.40832 2.02998 5.51272 2.42855 6.03849L4.44318 8.69872L9.51009 0.563059C9.85895 0.00334478 10.5956 -0.167912 11.1565 0.180431C11.7167 0.529116 11.8878 1.26626 11.5388 1.82649L5.55318 11.4368C5.34524 11.7709 4.98626 11.9811 4.59318 11.9989C4.57484 11.9996 4.55684 11.9999 4.53866 11.9999Z' fill='white'/%3E%3C/svg%3E");
    }
    &:hover {
      .overlay {
        display: none;
      }
    }
  }
}
.mediaContent {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);

  img {
    width: 4.125rem;
  }
}
.docs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
  background-color: #fff;
  color: #29394e;
  img {
    width: 1.625rem;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.125rem;
    max-height: 3.5rem;
    overflow: hidden;
  }
  span {
    font-size: 0.625rem;
    font-weight: 300;
  }
}
.docInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.play {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.75rem;
  background: linear-gradient(
    180deg,
    rgba(41, 57, 77, 0.48) 0%,
    rgba(41, 57, 77, 0.69) 23.96%,
    rgba(41, 57, 77, 0.97) 60.94%,
    rgba(41, 57, 77, 0.85) 100%
  );
  // box-shadow: 0px 4px 30px rgba(41, 57, 77, 0.6);
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  .editAlbumBtn {
    text-align: right;
    margin: 4px 7px;
  }
  .groupAction {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  .addToAlbum,
  .editMedia {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: #29394e;
      height: 1.75rem;
      min-width: 1.75rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
      border: none;
      cursor: pointer;
      &:hover {
        .label {
          width: 120px;
        }
      }
    }
    .icon {
      color: #fff;
    }
    .label {
      color: #18a0fb;
      font-size: 11px;
      color: #c7e8fe;
      font-weight: 500;
      width: 0;
      overflow: hidden;
      white-space: nowrap;
      transition: width 0.5s ease-in-out;
    }
    .check {
      border-radius: 50%;
      width: 1.125rem;
      height: 1.125rem;
      border: 2px solid #f1f2f4;
      background-color: #b2bcc6;
    }
  }
  .editMedia button {
    padding: 0 7px;
  }
  .date {
    font-size: 0.625rem;
  }
  h3 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25;
    max-height: 3.25rem;
    overflow: hidden;
    color: #fff;
  }
}
.masked {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.7);
  &::before {
    content: "";
    height: 2.25rem;
    background-color: rgba(255, 255, 255, 0.7);
    width: 5px;
    border-radius: 0px;
    position: absolute;
    transform: rotate(30deg);
    border-right: 3px solid rgba(0, 0, 0, 0.75);
  }
}
