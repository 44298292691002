.container {
  background: #ffffff;
  border: 1px solid #fe3745;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  width: 476px;
  max-width: 100%;
  margin: 200px auto;
  padding: 40px 20px;
  text-align: center;
}

.icon {
  width: 60px;
  height: 60px;
  background: #ffd7da;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;

  i {
    color: #fe3745;
    font-size: 20px;
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fe3745;
  margin-bottom: 10px;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #29394d;
}

.action {
  display: flex;
  justify-content: center;
  margin: 40px auto 0;
}
