.pageTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.pageHelpTitle {
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  margin-bottom: 1.5rem;
}

.form {
  background: #ffffff;
  border: 1px solid #18a0fb;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  margin-bottom: 2rem;
  position: relative;

  &_content {
    padding: 10px 20px 20px;

    &_pad {
      padding: 30px 20px 20px;
    }

    &.loading {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .titles {
    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #29394d;
      margin-bottom: 10px;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
      margin-bottom: 10px;
    }
  }

  &_title {
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    margin-bottom: 10px !important;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 1rem !important;
  padding: 20px;

  .tab {
    position: relative;
    width: 31%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #6d7f92;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;

    &.active,
    &:hover {
      color: #18a0fb;

      &:after {
        background-color: #18a0fb;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      background-color: #b2bcc6;
      border-radius: 50px;
    }
  }
}

.formManual {
  max-height: 420px;
  overflow-y: scroll;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);

  button {
    min-width: 114px;
    justify-content: center;
  }
}

// List result
.listContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    .refresh {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}

.tableContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  height: max-content;
  min-height: 300px;
  padding-bottom: 15px;
}

.table {
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;
    &::first-letter {
      text-transform: capitalize !important;
    }

    &::first-letter {
      text-transform: capitalize !important;
    }

    i {
      cursor: pointer;
      color: #6d7f92;

      &.iconActive {
        color: #29394d;
      }
    }

    .sortBox {
      margin-left: 5px;
    }
  }

  tr {
    background: #fafbfb;
    border-bottom: 2px solid #fff;
    border-radius: 2px;
    &.buttonContainer {
      background-color: white;
    }
  }

  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;

    &.centerCell {
      text-align: center;
    }

    .cellIcon {
      transform: rotate(90deg);
      cursor: pointer;
      font-size: 14px;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: #f1f2f4;
      }

      &.active {
        background-color: #18a0fb;
        color: #fff;
        font-size: 12px;
      }
    }
    &.dragCell {
      display: flex;
      align-items: center;
      margin-top: 0.4rem;

      .dragHandler {
        cursor: move;
        font-size: 2rem;
        padding: 0 !important;
        margin: 0 0.3rem 0.2rem 0 !important;

        i {
          font-size: 16px;
        }
      }
    }

    .icons {
      display: flex;
      justify-content: center;

      .close_icon {
        color: #fe3745;
      }

      .check_icon {
        color: #02af8e;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.lockIcon {
  color: #ef8318;
  font-size: 1rem;
  text-align: center;
}

.dropDownContainer {
  position: relative;
}

.menuDropdown {
  position: absolute;
  left: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #18a0fb;
  border-radius: 4px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  background: #fff;
  z-index: 1005;
  width: 200px;

  &:before {
    content: "";
    left: -10px;
    border-color: transparent transparent transparent #18a0fb;
    border-style: solid;
    border-width: 7px 0 7px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }

  li {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    line-height: 1.6;

    &:hover {
      background: #f8f9fa;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.inputSearch {
  display: flex;
  background: #fff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  border-radius: 30px;
  height: 32px;
  width: 300px;
  align-items: center;

  &_icon {
    color: #b7bdc3;
    cursor: pointer;
    font-size: 1.2rem;
    display: inherit;
    margin: auto 1rem;
  }

  input {
    width: 240px;
    border: none;
    outline: none;
    margin: 0;
    color: #3c4e64;
  }
}

.actionBar {
  border-top: 1px solid #f1f2f4;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);

  > div {
    display: flex;
    justify-content: space-between;
  }
}

.linkedContact {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #29394d;
  padding-top: 20px;
  border-top: 1px solid #f1f2f4;
}

.tabForms {
  display: flex;
  margin: 0 -20px 20px -20px !important;
  padding: 0;

  .active {
    color: #18a0fb;
  }

  li {
    height: 26px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #6d7f92;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-left: 1px solid #d8dde2;
    cursor: pointer;

    &:first-child {
      border: none;
    }
  }
}

.left {
  margin-left: auto;
  width: fit-content;

  button {
    display: block;
  }
}

.btDType {
  margin-left: 20px;
  cursor: pointer;
}

.delegation {
  font-size: 12px;
  white-space: nowrap;
  display: block;
}

.delegationAction {
  cursor: pointer;
  display: block;
  width: max-content;
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  color: white;
  font-weight: 500;
  font-size: 12px;
  background-color: #18a0fb;
}

.action {
  display: flex;
  align-items: center;
  justify-self: center;

  > input {
    margin-right: 4px;
  }
}
